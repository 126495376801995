import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation} from "react-router-dom";
import { useCookies } from "react-cookie";
import Loading from "../UtilComponents/Loading";
function Logout(props) {
	const location = useLocation();
	let navigate = useNavigate();
	useEffect(() => {
		return navigate("/login");
	}); 

	useEffect(() => {
        const currentDate = new Date(); 
        console.log("Current URL path:", location.pathname);
        console.log("Current date and time:", currentDate.toLocaleString()); 
    }, [location]);

	props.removeUserCookie("user_token");
	props.setUser(null); //clean up user data
	return <Loading show={"logging out..."} />;
}
export default Logout;
