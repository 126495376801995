import React, { Suspense, useMemo, useState } from "react";
import { Button, Form, FormControl, FormText, ProgressBar } from "react-bootstrap";
import { Await, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import { getAllCourses } from "../../util/courseutil";
import { getComplexities } from "../../util/cplxutil";
import { addFAQ, getFAQById, replaceFAQ } from "../../util/questionutil";
import Loading from "../UtilComponents/Loading";
import SelectComplexity from "../UtilComponents/SelectComplexity";
import SelectCourseHierarchy from "../UtilComponents/SelectCourseHierarchy";

import {
    faAdd,
    faCheckCircle,
    faEdit,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { afterSplice } from "../../util/generalutil";
import { TimePicker } from "../UtilComponents/CountDown";
const modeIcon = {
	edit: faEdit,
	add: faAdd,
};
export default function AddFAQ(props) {
	return (
		<Suspense fallback={<Loading show={"loading courses"} />}>
			<Await resolve={getAllCourses().catch(console.log)}>
				<GetCourseAndContinue />
			</Await>
		</Suspense>
	);
}

function GetCourseAndContinue(props) {
	const courseList = useAsyncValue().data;
	return (
		<Suspense fallback={<Loading show={"loading complexities"} />}>
			<Await resolve={getComplexities()}>
				<GetComplexityAndContinue courseList={courseList} {...props} />
			</Await>
		</Suspense>
	);
}
function GetComplexityAndContinue(props) {
	let complexityDict = useAsyncValue().data;
	let navigate = useNavigate();
	const [courseId, setCourseId] = useState(null);
	const [chapterId, setChapterId] = useState(null);
	const [topicId, setTopicId] = useState(null);
	const [complexity, setComplexity] = useState(null);
	const [invokeLoadCourseRef, setInvokeLoadCourseRef] = useState(false);
	const [text, setText] = useState("");
	const [hint, setHint] = useState("");
	const [opts, setOpts] = useState([""]);
	const [answerIndex, setAnswerIndex] = useState(0);
	const minTimeGiven = 15;
	const maxTimeGiven = 10 * 60;

	const [timeGiven, setTimeGiven] = useState(minTimeGiven);
	const params = useParams();
	useMemo(() => {
		if (params) {
			console.log(params);
			if (params.mode == "edit") {
				if (params.faq_id)
					getFAQById(params.faq_id)
						.then(function (value) {
							console.log(value.data);
							setCourseId(value.data.course_id);
							setInvokeLoadCourseRef(true);
							setChapterId(value.data.chapter_id);
							setTopicId(value.data.topic_id);
							setComplexity(value.data.complexity_id);
							setOpts(value.data.options);
							setAnswerIndex(value.data.answerIndex);
							setText(value.data.description);
							setHint(value.data.hint);
							setTimeGiven(value.data.timeGiven);
						})
						.catch(console.log);
			}
		}
	}, []);

	function validateForm() {
		return (
			courseId !== "" &&
			chapterId !== "" &&
			topicId !== "" &&
			courseId !== null &&
			chapterId !== null &&
			topicId !== null &&
			complexity !== null &&
			complexity !== "" &&
			opts.length > 0 &&
			text !== "" &&
			
			answerIndex !== null &&
			answerIndex !== "" &&
			text !== null 
			
		);
	}
	function handleSubmit(e) {
		e.preventDefault();
		if (validateForm()) {
			if (params.mode == "edit") {
				replaceFAQ(params.faq_id, courseId, chapterId, topicId, {
					description: text,
					hint: hint,
					complexity_id: complexity,
					options: opts,
					answerIndex: answerIndex,
					timeGiven: timeGiven,
				}).then((result) => {
					console.log(result);
					if (result.data != null) {
						alert("faq updated successfully");
						navigate(-1);
					}
				});
			} else if (params.mode == "add") {
				addFAQ(courseId, chapterId, topicId, {
					description: text,
					hint: hint,
					complexity_id: complexity,
					options: opts,
					answerIndex: answerIndex,
					timeGiven: timeGiven,
				}).then((result) => {
					console.log(result);
					if (result.data.acknowledged) {
						alert("faq added successfully");
						setOpts([""]);
						setAnswerIndex(0);
						setText("");
						setHint("");
					}
				});
			}
		} else {
			alert("please fill all the fields");
		}
	}
	const colors = [
		"danger",
		"warning",
		"primary",
		"secondary",
		"white",
		"white",
		"white",
		"white",
		"white",
		"white",
	];

	return (
		<Form onSubmit={handleSubmit} className="w-100 card p-3 gap-3 ">
			<div className="hstack gap-2">
				<div className="vstack align-items-center justify-content-between gap-2">
					<SelectCourseHierarchy
						courseList={props.courseList}
						invokeLoadCourseRef={invokeLoadCourseRef}
						setInvokeLoadCourseRef={setInvokeLoadCourseRef}
						courseId={courseId}
						setCourseId={setCourseId}
						chapterId={chapterId}
						setChapterId={setChapterId}
						topicId={topicId}
						setTopicId={setTopicId}
						autoFocus={true}
					/>

					<SelectComplexity
						complexityDict={complexityDict}
						complexity={complexity}
						setComplexity={setComplexity}
					/>

					<FormControl
						as={"textarea"}
						value={text}
						placeholder="Enter FAQ Question"
						className="h-50"
						onInput={(e) => setText(e.target.value)}
					/>
					<ProgressBar
						className="w-100"
						style={{ height: "2em" }}
						now={timeGiven}
						min={minTimeGiven}
						max={maxTimeGiven}
						animated
						striped
						variant={
							colors[Math.floor((timeGiven / maxTimeGiven) * (colors.length - 1))]
						}
					/>
					<FormText id="timeHelpBlock">
						<TimePicker
							time={timeGiven}
							onChange={(e) => setTimeGiven(e)}
							min={minTimeGiven}
							max={maxTimeGiven}
						/>
					</FormText>
				</div>
				<div className="vstack align-items-center justify-content-between gap-2">
					<FormControl
						type="text"
						value={hint}
						placeholder="Enter Hint"
						onInput={(e) => setHint(e.target.value)}
					/>
					<div className="card gap-2 ps-4 p-2">
						<h5 className="rot-90-text">Options</h5>
						<div className="gap-2">
							{opts.map((item, index) => {
								return (
									<div className="hstack gap-2 " key={index}>
										<FormControl
											type="text"
											value={item}
											placeholder="Enter Option"
											onInput={(e) =>
												setOpts(
													opts.map((item, i) =>
														i == index ? e.target.value : item
													)
												)
											}
										/>

										{index == answerIndex ? (
											<span className="badge bg-secondary text-dark">
												Correct
											</span>
										) : ( 
											<>
												<Button
													onClick={() => {
														setAnswerIndex((prev) =>
															Math.min(prev, opts.length - 1 - 1)
														);
														//here 0 to opts.length -1 indicate possible values for the answerIndex
														// additional -1 is added so that next slice will not include the last element
														//  set answer low if opts.length is greater than answerIndex

														setOpts([...afterSplice(opts, index, 1)]);
													}}
												>
													<FontAwesomeIcon icon={faTrash} />
												</Button>
												<Button onClick={() => setAnswerIndex(index)}>
													<FontAwesomeIcon
														icon={faCheckCircle}
														className=""
													/>
												</Button>
											</>
										)}
									</div>
								);
							})}

							<Button onClick={() => setOpts([...opts, ""])}>
								<FontAwesomeIcon icon={faAdd} className="me-2" />
								option
							</Button>
						</div>
					</div>
				</div>
			</div>

			<Button type="submit" className="align-self-center" disabled={!validateForm()}>
				<FontAwesomeIcon icon={modeIcon[params.mode]} className="ms-2 me-2" />
				<span className="text-capitalize">
					{params.mode}
					&nbsp;FAQ
				</span>
			</Button>
			{/* <Button type="submit" disabled={validateForm}>Submit</Button> */}
		</Form>
	);
}
