import { global_user as user } from "../components/PortalApp";
import { axiosWAuth } from "./axiosWAuth";
import { _url, adminOnly } from "./middleWareUtil";
export async function getAllStudents() {
	if (adminOnly(user)) {
		return axiosWAuth.get(_url.admin.getAllStudents);
	}
}
export async function convertVisitorToStudent(email) {
	if (adminOnly(user)) {
		console.log(_url.admin.users.update.roles.visToStu); 
        const body = { email: email }; 
		return axiosWAuth.post(_url.admin.users.update.roles.visToStu,body);
	}
}
