export function makePath(arr) {
	return arr.filter((e) => e != null && e != "").join("/");
}
export function strNotNull(value) {
	return value != null ? value : "";
}
export function splitN(s, n) {
	var output = [];
	for (var i = 0; i < s.length; i += n) {
		output.push(s.substr(i, n));
	}
	return output;
}
export function withoutElAtIndex(arr, index) {
	return arr.filter((e, i) => i != index);
}
export function validateEmailString(email) {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}
const compareArrays = (a, b) =>
	a.length === b.length && a.every((element, index) => element === b[index]);
window.compareArrays = compareArrays;
/**
 *
 * Validates data against a schema.
 *
 * @param {object} data - The data to be validated.
 * @param {object} schema - The schema to validate against.
 * @param {function} callback - The callback function to handle the result.
 */
export function validate(data, schema, callback) {
	try {
		if (typeof data != "object" && typeof schema != "object")
			if (typeof data == typeof schema) {
				return true;
			} else {
				console.log(data, schema);
				callback(false, "I don't know");
			}
		if (!compareArrays(Object.keys(schema).sort(), Object.keys(data).sort())) {
			throw "keys are different, invalid data";
		}

		return Object.keys(schema).every((key) => {
			let typeMatched = true;
			let formatMatched = true;

			if (schema[key].children) {
				schema[key].children.map((child, index) => {
					if (!validate(data[key], child, callback))
						//if a child fails
						return false;
				});
				return true; //if all children pass
			} else if (schema[key].type) {
				if (
					typeof data[key] === schema[key].type ||
					(typeof data[key] === "object" &&
						schema[key].type === "array" &&
						Array.isArray(data[key]))
				) {
					if (schema[key].childFormat) {
						if (
							data[key].every((el, index) => {
								let x = validate(el, schema[key].childFormat, callback);
								return x;
							})
						) {
						} else throw "type not matched for " + key;
					}
					typeMatched = true;
				} else typeMatched = false;
			}

			if (schema[key].format) {
				if (schema[key].format === "email" && validateEmailString(data[key])) {
					formatMatched = true;
				} else formatMatched = false;
			}

			if (!typeMatched) {
				throw "type not matched for " + key;
			}

			if (!formatMatched) {
				throw "format not matched for " + key;
			}
			if (typeMatched && formatMatched) return true;
		});
	} catch (err) {
		callback(false, err);
	}
}

export function validateCourseList(courseList) {
	if (Array.isArray(courseList)) {
		return [courseList].flat();
	} else return [];
}
export function checkDuplicates(arr) {
	if (arr.length == new Set(arr).size) return true;
	else return false;
}
export function removeSpChars(str) {
	let spchars = [" ", "'", ",", '"'];
	let temp = str;
	spchars.forEach((spCh) => (temp = temp.replaceAll(spCh, "")));
}
export function Zeros(num, len) {
	return (Array(len).join("0") + num).slice(-len);
}
export function splitArray(arr, x) {
	const result = [];
	let index = 0;

	while (index < arr.length) {
		result.push(arr.slice(index, index + x));
		index += x;
	}

	return result;
}
export function afterSplice(arr, splicIndex, noofel = 0, val = null) {
	let temp = [...arr];
	val ? temp.splice(splicIndex, noofel, val) : temp.splice(splicIndex, noofel);

	return temp;
}

export function giveQrLink(data, size = 200, encoding = "L") {
	return `https://api.qrserver.com/v1/create-qr-code/?data=${data}&size=${size}x${size}&encoding=${encoding}`;
}
