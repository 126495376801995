import React, { useEffect, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { faAdd, faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import "../../css/Leftnav.css";
import { UserContext } from "../../context/userContext";
import { getAllCourses } from "../../util/courseutil";

//accesskey that won't work ::e,d,f

const menuOpts = {
	simple: [
		{ privilige: "student", link: "/dashboard", text: "Das*h*board", accessKey: "h" },
		{ privilige: "staff", link: "/admission", text: "Admissions", accessKey: "" },
		{ privilige: "staff", link: "/evaluateexercise", text: "Evaluation", accessKey: "" },
		{ privilige: "staff", link: "/tracking", text: "Tracking", accessKey: "" },
		{ privilige: "staff", link: "/userinfodashboard", text: "UserInfo_Dashboard", accessKey: "" },
		{ privilige: "staff", link: "/logginghistory", text: "Login History", accessKey: "" },
		{ privilige: "staff", link: "/errorlogging", text: "Error Logging", accessKey: "" },
		{ privilige: "staff", link: "/edatozenquiry", text: "Edatoz Enquiry", accessKey: "" },
		{ privilige: "tutor", link: "/tutordashboard", text: "DASHBOARD", accessKey: "" },
		{ privilige: "tutor", link: "/courses", text: "Courses", accessKey: "" }
	],

	nested: [
		{
			privilige: "staff",
			name: "FAQ",
			list: [
				{ link: "/faq/add", text: "Add", accessKey: "", icon: faAdd, type: "action" },
				{
					link: "/faq/search",
					text: "Search",
					accessKey: "",
					icon: faSearch,
					type: "action",
				},
				{ link: "/faq", text: "Practice", accessKey: "P" },
			],
		},
		{
			privilige: "staff",
			name: "Quiz",
			list: [
				{ link: "/faq/add", text: "Add", accessKey: "", icon: faAdd, type: "action" },
				{
					link: "/faq/search",
					text: "Search",
					accessKey: "",
					icon: faSearch,
					type: "action",
				},
				{ link: "/faq", text: "Quiz", accessKey: "P" },
			],
		},
		{
			privilige: "staff",
			name: "courses",
			list: [
				{ link: "/courses/add", text: "Add", accessKey: "", icon: faAdd, type: "action" },
				{
					link: "/courses/search",
					text: "Search",
					accessKey: "",
					icon: faSearch,
					type: "action",
				},
			],
		},
		{
			privilige: "staff",
			name: "batches",
			list: [
				{ link: "/batches/add", text: "Add", accessKey: "", icon: faAdd, type: "action" },
				{
					link: "/batches/search",
					text: "Search",
					accessKey: "",
					icon: faSearch,
					type: "action",
				},
			],
		},
		{
			privilige: "staff",
			name: "tracks",
			list: [
				{ link: "/tracks/add", text: "Add", accessKey: "", icon: faAdd, type: "action" },
				{
					link: "/tracks/search",
					text: "Search",
					accessKey: "",
					icon: faSearch,
					type: "action",
				},
			],
		},
		{
			privilige: "staff",
			name: "Exercises",
			list: [
				{ link: "/ex/add", text: "Add", accessKey: "", icon: faAdd, type: "action" },
				{
					link: "/ex/search",
					text: "Search",
					accessKey: "",
					icon: faSearch,
					type: "action",
				},
				{ link: "/ex", text: " Practice", accessKey: "" },
			],
		},

	],
};

function convertFormat(link_before, data) {
	return data.map((item) => {
		return {
			link: link_before + item.course_id,
			text: item.course_name,
		};
	});
}

function convertFormatNotes(data) {
	return convertFormat("notes/sub/", data);
}

function convertFormatCourses(data) {
	return convertFormat("course/", data);
}

function LeftNav(props) {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	let user = useContext(UserContext);
	const [menu, setMenu] = useState(menuOpts);
	const [isDropdownOpen, setIsDropdownOpen] = useState([]);
	const [refresher, setRefresher] = useState(false)

	const handleDropdownToggle = (ind) => {
		isDropdownOpen[ind] = (isDropdownOpen[ind] == 0) ? 1 : 0;
		setIsDropdownOpen(isDropdownOpen);
		setRefresher(!refresher) // used to re-render the component
	};

	useEffect(() => {
		let arr = []
		menuOpts.nested.forEach(m => { arr.push(0) })
		setIsDropdownOpen(arr)
	}, []);

	useMemo(() => {
		getAllCourses()
			.then((results) => {
				if (results.data != "not enrolled in any batches")
					if (Array.isArray(results.data)) {
						if (results.data.length > 0) {
							setMenu({
								simple: [
									...menuOpts.simple,
									{
										privilige: "student",
										link: "/faq",
										text: "*P*ractice FAQ",
										accessKey: "P",
									},
									// {
									// 	privilige: "student",
									// 	link: "/faq",
									// 	text: "Quiz",
									// 	accessKey: "Q",
									// },

									{
										privilige: "student",
										link: "/ex",
										text: " Practice Exerci*s*es",
										accessKey: "S",
									},
								],
								nested: [
									...menuOpts.nested,
									{
										privilige: "student",
										name: "Notes",
										list: [...convertFormatNotes(results.data)],
									},
									{
										privilige: "staff",
										name: "Notes",
										list: [
											{
												link: "/notes/add",
												text: "Add",
												accessKey: "",
												icon: faAdd,
												type: "action",
											},
											{
												link: "/notes/search",
												text: "Search",
												accessKey: "",
												icon: faSearch,
												type: "action",
											},
											// ...convertFormatNotes(results.data),
										],
									},

								],
							});
						}
					}
			})
			.catch(console.log);
	}, []);

	//function which returns jsx that underlines text in string where it's present between two stars
	function processUnderLine(text) {
		const parts = text.split("*");

		return (
			<>
				{parts.map((part, index) =>
					index % 2 == 0 ? <span key={index}>{part}</span> : <u key={index}>{part}</u>
				)}
			</>
		);
	}

	function verifyPrivilige(privilige) {
		if (privilige == null) return true;
		if (user == null) {
		} else if (privilige == user.role) return true;
		return false;
	}
	
	return (
		<>
			<FontAwesomeIcon className="d-lg-none" size="2x" icon={faBars} onClick={handleShow} />

			<Offcanvas className="h-100" show={show} onHide={handleClose}
				responsive="lg">
				<Offcanvas.Header
					closeButton>
					<Offcanvas.Title>EDATOZ Portal Menu</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className="w-100 h-100">
					<p className="w-100 mb-0">
						<Navbar expand="lg"
							className={"w-100 h-100"}
						>
							<Nav className="mr-auto">
								{menu.simple.map(
									(item, itemIndex) =>
										verifyPrivilige(item.privilige) && (
											<>
												<Nav.Link
													as={Link}
													to={item.link}
													accessKey={item.accessKey}>
													{item.icon && (
														<FontAwesomeIcon
															icon={item.icon}
															className="me-2"
														/>
													)}
													{processUnderLine(item.text)}
												</Nav.Link>
											</>
										)
								)}

								{menu.nested.map(
									(item, itemIndex) =>
										verifyPrivilige(item.privilige) && (
											<>
												<NavDropdown
													title={
														<span>
															{item.name} {(isDropdownOpen[itemIndex] == 1) ? <ChevronDown size={15} /> : <ChevronRight size={15} />}
														</span>
													}
													id="basic-nav-dropdown"
													onToggle={() => { handleDropdownToggle(itemIndex) }}
													className="custom-dropdown"
												>
													{item.list.map((listItem, listItemIndex) => (
														<>
															<NavDropdown.Item key={listItemIndex}
																as={Link}
																to={listItem.link}
															>
																{listItem.icon && (
																	<FontAwesomeIcon
																		icon={listItem.icon}
																		className="me-2"
																	/>
																)}

																{processUnderLine(listItem.text)}
															</NavDropdown.Item>
														</>
													))}
												</NavDropdown>
											</>
										)
								)}
							</Nav>
						</Navbar>
					</p>
				</Offcanvas.Body>
			</Offcanvas>		
		</>
	);
}
export default LeftNav;
