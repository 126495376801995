import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import PortalApp from './components/PortalApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <StrictMode>    
        <PortalApp />
    // </StrictMode>
)