import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Navbar, } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../../css/Footer.css'
import {Row,Col} from "react-bootstrap";
let socials = [
    { link: 'https://www.facebook.com', icon: faFacebook, color: '#3b5998' },
    { link: 'https://www.instagram.com/EdAtoZ_Beetles/', icon: faInstagram, color: '#d62976' },
    { link: 'https://www.linkedin.com/company/edatoz/', icon: faLinkedin, color: '#0072b1' },
    { link: 'https://www.twitter.com', icon: faTwitter, color: '#00acee' }
];
function Footer() {
    return (
        <Navbar className="footer fixed-bottom">
            <Row
                direction="horizontal"
                className="w-100 flex-wrap">
                <Col className="slogan justify-content-center">
                    Enter To Learn Leave To Earn
                </Col>

                <Col
                    direction="horizontal"
                    className="justify-content-evenly"
                   >

                    <div className="copyright d-flex justify-content-center">

                        <FontAwesomeIcon icon={faCopyright }  style={{marginTop : "5px",paddingRight: "5px"}} ></FontAwesomeIcon>

                        <div className="d-flex flex-column">
                            <span> edAtoZ 2024</span>

                        </div>

                    </div>
                </Col>
                <Col>
                    <div className="d-flex flex-column">

                        <div className="socialContainer d-flex justify-content-center">

                            {
                                socials.map((social, indexSocial) => (
                                    <a key={indexSocial}
                                        target="_blank"
                                        href={social.link}
                                        className="social m-1"
                                        style={{ '--origColor': social.color }}>
                                        <FontAwesomeIcon icon={social.icon} size="2x" ></FontAwesomeIcon>
                                    </a>
                                )
                                )
                            }
                        </div>

                    </div>

              </Col>

            </Row>

        </Navbar>
    );
}
export default Footer;