import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Home() {
	const links = [
		{ to: "https://www.edatoz.com", text:"edatoz.com",new_tab: true },
		{ text: "home", to: "/home" }, 
		{ text: "contact", to: "/contactus" },
	];
	return (
		<div className="vstack align-items-center align-self-center justify-content-evenly">
			<h1 style={{ fontStyle: "normal" }}>Welcome to EdAtoZ</h1>
			<div className="links hstack gap-2 justify-content-center align-items-center">
				{links.map((e, i) => (
					<>
						<Link
							key={i}
							className="eachLink hstack gap-2" 
							to={e.to}
							target={e.new_tab ? "_new" : "_self"}
						>
							{e.text || e.to}
							{e.new_tab && <FontAwesomeIcon icon={faSquareArrowUpRight} />}
						</Link>
						{i < links.length - 1 && <div className="vr"></div>}
					</>
				))}
			</div>
		</div>
	);
}
export default Home;
