import React, { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { convertVisitorToStudent } from "../../util/userutil";

function Admission() {
	const [email, setEmail] = useState("");
	const emailSuggestions = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com"];
	function validate() {
		if (
			email == null ||
			email == "" ||
			!email.includes("@") ||
			email.indexOf("@") != email.lastIndexOf("@") ||
			email.lastIndexOf("@") == email.length - 1
		) {
			return false;
		}
		return true;
	}
	function handleSubmit(e) {
		e.preventDefault();
		if (validate()) {
			console.log(email);
			convertVisitorToStudent(email)
				.then((res) => {
					console.log(res);
					if (res.status == 200) {
						alert("User added successfully");
						setEmail("");
					}
				})
				.catch((err) => {
					if (err.response.status == 404) {
						alert("User not yet registered");
					} else if (err.response.status == 400) {
						alert("User already registered as student");
                        setEmail("");
					} else if (err.response.status == 500) {
						alert("Something went wrong");
					}

					console.log(err);
				});
		}
	}

	return (
		<Form onSubmit={handleSubmit} className=" w-100  maxw600">
			<div className="card w-100 p-3 vstack justify-content-center gap-3  ">
				{" "}
				<FormControl
					type="email"
					autoComplete="email"
					value={email}
					autoFocus
					placeholder="Email of the student"
					onKeyDown={(e) => {
                        console.log(e)
						//validate it against regex /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
						if (e.code==undefined||
							e.code.startsWith("Key") ||
							e.code.startsWith("Digit") ||
							e.code.startsWith("Minus") ||
							e.code.startsWith("Numpad") ||
							e.code.startsWith("Period") ||
							e.code.startsWith("Slash") ||
							e.code.startsWith("Equal") ||
							e.code.startsWith("Semicolon") ||
							e.code.startsWith("Comma") ||
							e.code.startsWith("Space") ||
							e.code.startsWith("Quote") ||
							e.code.startsWith("BracketRight") ||
							e.code.startsWith("BracketLeft") ||
							e.code.startsWith("Backslash") ||
							e.code.startsWith("Backquote") ||
							e.code.startsWith("Slash")
						)
							if (e.key.match(/[a-zA-Z0-9.@]/) == null) e.preventDefault();
					}}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<div className="hstack justify-content-end ">
					{email == "" ||
						emailSuggestions
							.filter((e) =>
								email.split("@")[1] ? e.search(email.split("@")[1]) != -1 : true
							) //filter based on domain
							.filter((e) => e != email.split("@")[1]) //don't show current domain
							.map((i, index) => (
								<Button
									key={index}
									className="suggestion hstack small bg-transparent outline-0 border-2 rounded-pill border-secondary"
									onClick={() => setEmail((e) => e.split("@")[0] + "@" + i)}
								>
									<div className=" small theatsymbol">
										{" "}
										{email.includes("@") || "@"}
									</div>
									<div className=" small domain"> {i}</div>
								</Button>
							))}
				</div>
				<Button type="submit" disabled={!validate()}>
					Submit
				</Button>
			</div>
		</Form>
	);
}

export default Admission;
