import React, { useState } from 'react';
import DSTutorial from '../Tutor/DataScience';
import MachineLearningTutorial from '../Tutor/MachineLearning';
import PythonTutorial from '../Tutor/Python';
import TutorAnnotations from '../../components/Tutorannotations';
import PowerBiTutorial from '../Tutor/PowerBiTutorial';

function Courses() {
    const [courseType, setCourseType] = useState('');

    const handleChange = (event) => {
        setCourseType(event.target.value);
    };

    let modelsComponent;
    if (courseType === 'DS') {
        modelsComponent = <DSTutorial />;
    } else if (courseType === 'ML') {
        modelsComponent = <MachineLearningTutorial />;
    } else if (courseType === 'Python') {
        modelsComponent = <PythonTutorial />;
    } else if (courseType === 'Reactjs') {
        modelsComponent = <TutorAnnotations />;
    } else if (courseType === 'PowerBi') {
        modelsComponent = <PowerBiTutorial />;
    }else {
        modelsComponent = null;
    }

    return (
        <div style={{Padding: '20px'}}>
            <label>Select Course Type :</label> &nbsp;&nbsp;&nbsp;&nbsp;
            <select value={courseType} onChange={handleChange}>
                <option value="">Select</option>
                <option value="DS">Data Science</option>
                <option value="ML">Machine Learning</option>
                <option value="Python">Python</option>
                <option value="Reactjs">React Js</option>
                <option value="PowerBi">PowerBi</option>
            </select>
            {modelsComponent}
        </div>
    );
}

export default Courses;
