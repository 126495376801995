import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form, FormCheck, FormLabel, ProgressBar } from "react-bootstrap";
import { faInfoCircle, faSquareArrowUpRight, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makePath } from "../../util/generalutil";

function PracticeQuestion({ skip, isAnswered, handleSubmit, question,
								 complexityDict, whenTimerEnds }) {
	let disabled = isAnswered;
	let pauseTimer = isAnswered;
	let timeNow = 0;

	const [timer_progress, setTimerProgress] = useState(0);
	const [answerOpt, setAnswerOpt] = useState(null);
	const colors = ["danger",
		"warning",
		"primary",
		"secondary",
		"white"
	]
	function handleCheckAnswer(e) {
		handleSubmit(e, question, answerOpt, question.timeGiven - timeNow);
	}

	function handleSkip() {
		skip("skip");
	}

	let bgVariant = colors[Math.floor(timer_progress * colors.length)];
	return (
		<Form className="practice-faq-question card h-100 w-100 p-4">
			{/* <ProgressBar key={question._id} 
            className="mb-3"
             now={timer_progress*100} 
             variant={bgVariant}
             /> */}
			<FormLabel className="hstack">
				<span>{question.description}</span>
				<span className="badge bg-white text-dark">
					{
						complexityDict.find((item) => item.complexity_id == question.complexity_id)
							.complexity_name
					}
				</span>
			</FormLabel>
			<div className="hstack user-select-none justify-content-between align-items-center mb-1">
			{question.hint && (
				<div className="alert alert-info p-0 px-2 d-inline m-0 ">
					<FontAwesomeIcon icon={faInfoCircle} className="me-2" />
					{question.hint}
				</div>
			)}
				<span className="badge bg-light text-dark">{question.meta?.status}</span>
			</div>
			<div className="user-select-none text-muted">
				{question.meta?.notes?.stu_status == "completed"
					? "Got stuck? re-refer "
					: "For a better shot, first read your "}
				<Link target="_blank"
					to={makePath([
						"/notes",
						"sub",
						question.course_id,
						question.chapter_id,
						question.topic_id,
					])}
				>
					notes
					<FontAwesomeIcon icon={faSquareArrowUpRight} />
				</Link>
			</div>
			{/* <FormLabel>{question._id}</FormLabel>
			<FormLabel>{question.course_id}</FormLabel>
			<FormLabel>{question.chapter_id}</FormLabel>
			<FormLabel>{question.topic_id}</FormLabel> */}
			<hr />
			<div className="hstack">
				<div className="vstack">
					{question.options.map((opt, index) => (
						<FormCheck
							disabled={disabled}
							type="radio"
							name="option-prac-faq"
							id={"opt-" + index}
							label={opt}
							key={index}
							onChange={(e) => (setAnswerOpt(index))}
						/>
					))}
				</div>
				{/* <div className="vstack align-items-center justify-content-center">
					<CountDown
                    variant={bgVariant}
						onProgress={(e) =>{timeNow=e; setTimerProgress((e / question.timeGiven) )}}
						key={question._id}
						time={question.timeGiven}
						whenDone={() => whenTimerEnds()}
						pause={pauseTimer}
                        
					/>
				</div> */}
			</div>

			<hr />
			<div>
				<Row>
					<Col xs={9} sm={10} md={10}>
						<Button disabled={disabled} onClick = {(e) => {handleCheckAnswer(e, question)}}>
							{" "}
							Check Answer{" "}
						</Button></Col>
					<Col xs={3} sm={2} md={2}>
						<Button onClick={handleSkip}>Skip <FontAwesomeIcon icon={faAngleRight} /></Button>
					</Col>
				</Row>
			</div>


		</Form>
	);
}
export default PracticeQuestion;
