import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useState } from "react";
import { Button, Container, Form, Row, Col} from "react-bootstrap";
import { Await, useAsyncValue, useNavigate } from "react-router-dom";
import { getAllCourses } from "../../util/courseutil";
import Loading from "../UtilComponents/Loading";
import SelectCourseHierarchy from "../UtilComponents/SelectCourseHierarchy";
import { makePath, validateCourseList } from "../../util/generalutil";

function SetExercises(props) {
	const courseList = validateCourseList(useAsyncValue().data);
	function validate() {
		return courseId != null && courseId != "";
	}
	function handleSubmit(e) {
		e.preventDefault();
		if (validate()) props.onsubmit(courseId, chapterId, topicId);
	}
	let [courseId, setCourseId] = useState(null);
	let [chapterId, setChapterId] = useState(null);
	let [topicId, setTopicId] = useState(null);
	return (
		<div>
			<Form onSubmit={handleSubmit} className="card p-3 gap-3">
				<Container fluid>
					<Row>
						<Col md={10}>
				<SelectCourseHierarchy
					courseList={courseList}
					courseId={courseId}
					setCourseId={setCourseId}
					chapterId={chapterId}
					setChapterId={setChapterId}
					topicId={topicId}
					setTopicId={setTopicId}
                    autoFocus
				/>
				</Col>
				<Col md={2} className="d-flex justify-content-end">
				<div className="pe-3 pt-2">
				<Button variant="primary" type="submit" disabled={!validate()}>
					START
					<FontAwesomeIcon className="ms-2 me-2" icon={faAngleRight} />
				</Button>
				</div>
				</Col>
				</Row>
				</Container>
			</Form>
		</div>
	);
}
function SelectFAQ() {
	let navigate = useNavigate();
	function navigateExercises(course_id, chapter_id, topic_id) {
		navigate(makePath(["do", course_id, chapter_id, topic_id]));
	}

	return (
		<div>
			<Suspense fallback={<Loading show={"loading faq courses"} />}>
				<Await resolve={getAllCourses().catch(console.log)}>
					<SetExercises onsubmit={navigateExercises} />
				</Await>
			</Suspense>
		</div>
	);
}

export default SelectFAQ;
