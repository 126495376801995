import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import {
	Button,
	Form,
	FormCheck,
	FormControl,
	FormGroup,
	FormLabel,
	FormSelect,
} from "react-bootstrap";
import { Await, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import { addBatch, getBatchById, replaceBatch } from "../../util/batchutil";
import { getAllTracks } from "../../util/trackutil";
import { getAllStudents } from "../../util/userutil";
import Loading from "../UtilComponents/Loading";
function giveDuration(days) {
	if (days == "1") return "1 day";
	if (isNaN(days)) return "";
	return days + "days";
}
function FormBatch(props) {
	const [batch, setBatch] = useState({ students: [] });
	const [tracks, setTracks] = useState([...useAsyncValue().data]);
	const [warn, setWarn] = useState("");
	const params = useParams();
	const navigate = useNavigate();
	useMemo(() => {
		if (params.mode == "edit")
			if (params.batch_id)
				getBatchById(params.batch_id).then(function (value) {
					console.log(value.data);
					setBatch({
						...value.data,
						start: new Date(value.data.start).toISOString().slice(0, 10),
						expectedEnd: new Date(value.data.expectedEnd).toISOString().slice(0, 10),
					});
				});
	}, [params]);
	useEffect(() => {
		validateForm();
	}, [batch]);
	function processBatch(_batch) {
		let _batchTemp = { ..._batch };
		_batchTemp.expectedEnd = new Date(_batchTemp.expectedEnd).valueOf();
		_batchTemp.start = new Date(_batchTemp.start).valueOf();
		return _batchTemp;
	}
	function validateForm() {
		let x = processBatch(batch);

		if (batch.batch_name == "" || batch.batch_name == null) {
			setWarn("set batch name");
			return false;
		}
		if (batch.start == "" || batch.start == null) {
			setWarn("set start date");
			return false;
		}
		if (batch.expectedEnd == "" || batch.expectedEnd == null) {
			setWarn("set expected end date");
			return false;
		}
		if (x.expectedEnd < x.start) {
			setWarn("expected end date cannot be before start date");
			return false;
		}
		if (x.expectedEnd == x.start) {
			setWarn("expected end date cannot be same as start date");
			return false;
		}
		if (batch.track_id == "" || batch.track_id == null) {
			setWarn("set track");
			return false;
		}
		if (!tracks?.find((e) => e.track_id == batch?.track_id)?.courses?.length) {
			setWarn("track contains no courses");
			return false;
		}
		if (batch.students.length == 0) {
			setWarn("set students");
			return false;
		}

		setWarn("");
		return true;
	}
	function handleSubmit(e) {
		e.preventDefault();
		if (validateForm()) {
			console.log(batch);
			if (params.mode == "edit") {
				replaceBatch(batch._id, processBatch(batch)).then((result) => {
					console.log(result);
					if (result.data.acknowledged) {
						alert("batch updated successfully");
						navigate(-1);
					}
				});
			} else if (params.mode == "add") {
				addBatch(processBatch(batch)).then((result) => {
					if (result.data.acknowledged) {
						alert("batch added successfully");
						setBatch({ students: [] });
					} else {
						console.log(result.data);
						alert(result.data);
					}
				});
			}
		}
	}

	return (
		<Form
			onSubmit={handleSubmit}
			className="manageBatch w-100 h-100 overflow-y-auto overflow-x-hidden "
		>
			<div className="w-100 h-100 vstack align-items-start justify-content-center gap-2">
				<FormControl
					type="text"
					placeholder="batch name"
					value={batch.batch_name}
                    autoFocus
					onChange={(e) => setBatch({ ...batch, batch_name: e.target.value })}
				/>
				<div className="datesCont hstack gap-2 justify-content-between">
					<div className="vstack gap-2 ">
						<FormGroup controlId="start" className="hstack justify-content-between card">
							<FormLabel>Starts at:</FormLabel>
							<FormControl
								type="date"
								className="max-w-min"
								value={batch.start}
								aria-label="select start date"
								onChange={(e) => setBatch({ ...batch, start: e.target.value })}
							/>
						</FormGroup>
						<FormGroup controlId="end" className="hstack justify-content-between card">
							<FormLabel>Expected to be ended at:</FormLabel>
							<FormControl
								type="date"
								className="max-w-min"
								value={batch.expectedEnd}
								aria-label="select end date"
								onChange={(e) =>
									setBatch({ ...batch, expectedEnd: e.target.value })
								}
							/>
						</FormGroup>
					</div>
					<div className="durationCourse card">
						{giveDuration(
							(
								Math.round(
									new Date(batch.expectedEnd).getTime() -
										new Date(batch.start).getTime()
								) /
								(1000 * 60 * 60 * 24)
							).toFixed(0)
						)}
					</div>
				</div>
				<div className="trackDetailsCont hstack gap-2 justify-content-between">
					<FormGroup controlId="track" className=" vstack w-100 card">
						<FormLabel>Track</FormLabel>
						<FormSelect
							disabled={params.mode == "edit"}
							value={batch.track_id}
							onChange={(e) =>
								setBatch({
									...batch,
									track_id: e.target.value == "select" ? "" : e.target.value,
									track_name:
										e.target.value == "select"
											? ""
											: tracks.find(
													(track) => track.track_id == e.target.value
											  ).track_name,
								})
							}
						>
							<option value="select">Select track</option>
							{tracks.map((track, trackIndex) => (
								<option key={trackIndex} value={track.track_id}>
									{track.track_name}
								</option>
							))}
						</FormSelect>
					</FormGroup>
					<div className="courses w-100 card">
						{tracks ? (
							tracks.find((e) => e.track_id == batch.track_id) ? (
								tracks.find((e) => e.track_id == batch.track_id).courses ? (
									<div className="courseContainer">
										Courses Offered:
										{tracks
											.find((e) => e.track_id == batch.track_id)
											.courses.map((course, courseIndex) => (
												<div
													className="course hstack justify-content-between "
													key={courseIndex}
												>
													<div className="courseName">
														{course.course_name}
													</div>
													<div className="courseId text-muted">
														~{course.course_id}
													</div>
												</div>
											))}
									</div>
								) : (
									<div className="alert alert-warning p-1 d-inline-block m-2">
										<FontAwesomeIcon icon={faWarning} className="mx-2" />
										no courses available in this track
									</div>
								)
							) : (
								""
							)
						) : (
							"no tracks available"
						)}
					</div>
				</div>
				<div className="card w-100  p-2 student-select h-25 overflow-y-auto">
					<div className="h4">Add Students </div>
					{props.students.map((student, studentIndex) => (
						<FormGroup
							key={studentIndex}
							controlId={student.id}
							className="hstack justify-content-between"
						>
							<FormLabel>{student.displayName}</FormLabel>
							<FormCheck
								type="checkbox"
								checked={batch.students.includes(student._id)}
								onChange={(e) => {
									setBatch({
										...batch,
										students:
											batch.students.includes(student._id) &&
											!e.target.checked
												? batch.students.filter((e) => e !== student._id)
												: [...batch.students, student._id],
									});
								}}
								className="w-100 hstack justify-content-end"
							/>
						</FormGroup>
					))}
				</div>
				{warn != "" && (
					<div className="alert alert-info p-1 d-inline-block">
						<FontAwesomeIcon icon={faWarning} className="mx-2" />
						{warn}
					</div>
				)}
				<div className="JSONresult d-none">result:{JSON.stringify(batch)}</div>
				<Button type="submit" className="align-self-center" disabled={warn != ""}>
					{params.mode == "edit" ? "Update" : "Add"}
					&nbsp;batches
				</Button>
			</div>
		</Form>
	);
}
function CompWithTracks() {
	return (
		<Suspense fallback={<Loading show={"loading tracks"} />}>
			<Await resolve={getAllTracks().catch(console.log)}>
				<FormBatch students={[...useAsyncValue().data]} />
			</Await>
		</Suspense>
	);
}
function ManageBatches() {
	return (
		<Suspense fallback={<Loading show={"loading tracks"} />}>
			<Await resolve={getAllStudents().catch(console.log)}>
				<CompWithTracks />
			</Await>
		</Suspense>
	);
}

export default ManageBatches;
