import React, { Suspense } from "react";
import { Container, FormSelect, Row, Col} from "react-bootstrap";

import { useState, useEffect } from "react";
import { Await, useAsyncValue } from "react-router-dom";
import { strNotNull } from "../../util/generalutil";
function SelectComplexity(props) {
	let [complexityId, setComplexityId] = useState(null);

	let complexityDict = props.complexityDict;

	useEffect(() => {
		if (props.mode == "EDIT") {
			setComplexityId(props.complexityId);
		} else {
		}
	}, []);

	return (
		<>
			<Row>
				<Col>
					<FormSelect
						className="user-select-none"
						disabled={props.disabled}
						value={strNotNull(props.complexity)}
						onChange={(e) => props.setComplexity(e.target.value == "select" ? "" : e.target.value)}
						autoFocus={props.autoFocus}
					>
						<option value="select">Select Complexity</option>
						{complexityDict.map((item, index) => (
							<option key={index} value={item.complexity_id}>
								{item.complexity_name}
							</option>
						))}
					</FormSelect>
				</Col>
			</Row>
		</>
	);
}

export default SelectComplexity;
