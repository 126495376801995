import { faAdd, faEdit, faListCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense } from "react";
import {Alert, Button, Table } from "react-bootstrap";
import { Await, Link, useAsyncValue } from "react-router-dom";
import { deleteBatch, getAllBatches } from "../../util/batchutil";
import { splitArray } from "../../util/generalutil";
import Loading from "../UtilComponents/Loading";
function DisplayEachBatch(props) {
	function handleDelete(x) {
		return () => {
			if (window.confirm("Are you sure you want to delete this batch?"))
				if (window.confirm("Really?"))
					deleteBatch(x)
						.then((results) => {
							console.log(results);
							if (results.data != null) {
								alert("deleted");
								window.location.reload();
							}
						})
						.catch((err) => {
							console.log(err);
							alert("an error occurred");
							window.location.reload();
						});
		};
	}
	return (
		<tr>
			<td>
				<div className="id vstack small text-muted">
					{splitArray(props.batch._id, 6).map((e, i) => (
						<span key={i}>{e}</span>
					))}
				</div>
			</td>
			<td>{props.batch.batch_name}</td>
			<td>{new Date(props.batch.start).toDateString()}</td>
			<td>{new Date(props.batch.expectedEnd).toDateString()}</td>
			<td>
				<div className="hstack justify-content-between">
					<span className="track_name">{props.batch.track_name}</span>
					<span className="track_id text-muted small">~{props.batch.track_id}</span>
				</div>
			</td>
			<td>
				{props.batch.courses.map((course, courseIndex) => (
					<div className="hstack justify-content-between" key={courseIndex}>
						<span className="track_name">{course.course_name}</span>
						<span className="track_id text-muted small">~{course.course_id}</span>
					</div>
				))}
			</td>
			<td>
				<Link className="btn btn-primary" to={`/batches/progress/${props.batch._id}`}>
					<FontAwesomeIcon icon={faListCheck} />
				</Link>
			</td>
			<td>
				<Link className="btn btn-primary" to={`/batches/edit/${props.batch._id}`}>
					<FontAwesomeIcon icon={faEdit} />
				</Link>
			</td>
			<td>
				<Button className="btn btn-danger" onClick={handleDelete(props.batch._id)}>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			</td>
		</tr>
	);
}
function DisplayBatches() {
	const batches = useAsyncValue().data;
	return (
		<>
		<div className="w-100 batches position-relative w-100 h-100 vstack align-items-center justify-content-center">
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Batch ID</th>
						<th>Batch Name</th>
						<th>Start</th>
						<th>Expected End</th>
						<th>Track</th>
						<th>Courses</th>
						<th>Progress</th>
						<th>Edit</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					{batches.map((batch, batchIndex) => (
						<DisplayEachBatch batch={batch} key={batchIndex} />
					))}
					{
						batches.length == 0 &&
						// <p className="text-center">No courses found</p>
						<Alert variant="primary">
							<Alert.Heading>No Batches found!</Alert.Heading>
							<p>
								There are no Batches currently availale in the system.
							</p>
							<hr />
							You are the lucky one to <Alert.Link href="/batches/add">add the first Batch</Alert.Link>
						</Alert>
					}
				</tbody>
			</Table>
				{/* <Link className="add btn btn-primary position-absolute bottom-0 end-0 m-3" to="/batches/add">
					<FontAwesomeIcon icon={faAdd} />
				</Link> */}
		</div>
		
		</>
	);
} 
function SearchBatches() {
	return (
		<Suspense fallback={<Loading show={"loading batches"} />}>
			<Await resolve={getAllBatches().catch(console.log)}>
				<DisplayBatches />
			</Await>
		</Suspense>
	);
}

export default SearchBatches;
