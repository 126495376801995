import {
    faBookOpen,
    faCheck,
    faFileCircleXmark,
    faFilter,
    faInfoCircle,
    faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, FormCheck, Form } from "react-bootstrap";
import { Await, Link, useAsyncValue, useParams, useLocation } from "react-router-dom";
import "../../css/Subject.css";
import "../../css/util.css";
import { getSubjectNotes } from "../../util/courseutil";
import { makePath } from "../../util/generalutil";
import { updateProgressNotes } from "../../util/progressutil";
import Loading from "../UtilComponents/Loading";
import { UserContext } from "../../context/userContext";
import { FaFilter } from 'react-icons/fa';

function DisplaySubject(props) {
    const location = useLocation();
    let resultWData = useAsyncValue();
    let [notes, setNotes] = useState({ ...resultWData.data });
    const [filteredNotes, setFilteredNotes] = useState(notes);
    const [filters, setFilters] = useState({ stu_status: "incomplete" });
    const [selectedChapters, setSelectedChapters] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [displayedChapter, setDisplayedChapter] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    let scrollRef = useRef();
    let scrollToTopic = props.scrollToTopic;

    useEffect(() => {
        setNotes(resultWData.data);
    }, [resultWData]);

    useEffect(() => {
        scrollRef?.current?.scrollIntoView({ block: "center" });
    }, [scrollRef.current]);

    useEffect(() => {
        const currentDate = new Date();
        console.log("Current URL path:", location.pathname);
        console.log("Current date and time:", currentDate.toLocaleString());
    }, [location]);

    const status_icon = {
        all: faFileCircleXmark,
        complete: faCheck,
        incomplete: faBookOpen,
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    function commitNotes(n_ts, chapter_id, topic_id, status, at) {
        return {
            course_id: n_ts.course_id,
            course_name: n_ts.course_name,
            chapters: n_ts.chapters.map((chapter) => ({
                chapter_id: chapter.chapter_id,
                chapter_name: chapter.chapter_name,
                topics: chapter.topics.map((topic) =>
                    chapter.chapter_id === chapter_id && topic.topic_id === topic_id
                        ? {
                            ...topic,
                            meta: {
                                ...topic.meta,
                                notes: {
                                    stu_status: status,
                                    stu_done_at: at,
                                },
                            },
                        }
                        : topic
                ),
            })),
        };
    }

    useEffect(() => {
        setFilteredNotes({
            course_id: notes.course_id,
            course_name: notes.course_name,
            chapters: notes.chapters
                .filter((chapter) =>
                    selectedChapters.length === 0 || selectedChapters.includes(chapter.chapter_id)
                )
                .map((chapter) => ({
                    chapter_id: chapter.chapter_id,
                    chapter_name: chapter.chapter_name,
                    topics: chapter.topics.filter(
                        (topic) =>
                            (filters.stu_status === "all" ||
                                filters.stu_status === topic.meta?.notes?.stu_status) &&
                            (selectedTopics.length === 0 || selectedTopics.includes(topic.topic_id))
                    ),
                }))
                .filter((chapter) => chapter.topics.length > 0),
        });
    }, [filters, notes, selectedChapters, selectedTopics]);

    let user = useContext(UserContext);

    const handleChapterChange = (chapter_id) => {
        if (selectedChapters.includes(chapter_id)) {
            setSelectedChapters(prev => prev.filter(id => id !== chapter_id));
            setDisplayedChapter(null);
        } else {
            setSelectedChapters(prev => [...prev, chapter_id]);
            setDisplayedChapter(chapter_id);
        }
        setSelectedTopics([]);
    };

    const handleTopicChange = (topic_id) => {
        setSelectedTopics(prev => {
            const newTopics = prev.includes(topic_id)
                ? prev.filter(id => id !== topic_id)
                : [...prev, topic_id];
            return newTopics;
        });
    };

    const handleFilterChange = (status) => {
        setFilters({ stu_status: status });
    };

    const renderChapterContent = (chapter_id) => {
        const chapter = notes.chapters.find(ch => ch.chapter_id === chapter_id);
        if (!chapter) return null;

        const filteredTopics = chapter.topics.filter(topic => 
            selectedTopics.length === 0 || selectedTopics.includes(topic.topic_id)
        );

        return (
            <div className="chapter">
                <h3 className="mb-3 mt-3 fw-bold">{chapter.chapter_name}</h3>
                {filteredTopics.map((topic, topicIndex) => (
                    <div
                        className="topic vstack"
                        key={topicIndex}
                        {...(props.scrollToCh === chapter.chapter_id &&
                            scrollToTopic === topic.topic_id
                            ? {
                                ref: scrollRef,
                            }
                            : {})}
                    >
                        <div className="hstack justify-content-between">
                            <h4 className="ps-3">{topic.topic_name}</h4>
                            <small className="badge badge-pill bg-light text-dark">
                                {topic.meta?.notes?.stu_status}
                                {status_icon[topic.meta?.notes?.stu_status] && (
                                    <FontAwesomeIcon
                                        className="ms-2"
                                        icon={status_icon[topic.meta?.notes?.stu_status]}
                                    />
                                )}
                            </small>
                        </div>

                        <ul className="ps-5">
                            {topic.content && topic.content.map((point, pointIndex) => (
                                <li key={pointIndex} className="mb-1">
                                    <div dangerouslySetInnerHTML={{ __html: point }} />
                                </li>
                            ))}
                        </ul>
                        {user.role === "student" && (
                            <>
                                {topic.meta?.notes?.stu_status === "complete" ? (
                                    (topic.meta?.exercises?.stu_status === "not_done" ||
                                        topic.meta?.faq?.stu_status === "done") && (
                                        <div
                                            className="hstack align-items-center text-muted user-select-none alert alert-info p-1 gap-2  "
                                            style={{ width: "fit-content" }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                className=""
                                            />
                                            Test yourself
                                            <div className="hstack gap-2">
                                                {topic.meta?.exercises?.stu_status ===
                                                    "not_done" && (
                                                        <Link
                                                            className="link-info"
                                                            to={makePath([
                                                                "/ex/do",
                                                                notes.course_id,
                                                                chapter.chapter_id,
                                                                topic.topic_id,
                                                            ])}
                                                        >
                                                            Exercise
                                                            <FontAwesomeIcon
                                                                icon={faSquareArrowUpRight}
                                                                className="ms-2"
                                                            />
                                                        </Link>
                                                    )}
                                                {topic.meta?.exercises?.stu_status ===
                                                    "not_done" &&
                                                    topic.meta?.faq?.stu_status ===
                                                    "not_done" && (
                                                        <div className="vr"></div>
                                                    )}
                                                {topic.meta?.faq?.stu_status ===
                                                    "not_done" && (
                                                        <Link
                                                            className="link-info"
                                                            to={makePath([
                                                                "/faq/do",
                                                                notes.course_id,
                                                                chapter.chapter_id,
                                                                topic.topic_id,
                                                            ])}
                                                        >
                                                            FAQ
                                                            <FontAwesomeIcon
                                                                icon={faSquareArrowUpRight}
                                                                className="ms-2"
                                                            />
                                                        </Link>
                                                    )}
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <Button
                                        onClick={() => {
                                            updateProgressNotes(
                                                filteredNotes.course_id,
                                                chapter.chapter_id,
                                                topic.topic_id,
                                                "complete"
                                            ).then((result) => {
                                                if (result.data.acknowledged === true)
                                                    setNotes(
                                                        commitNotes(
                                                            notes,
                                                            chapter.chapter_id,
                                                            topic.topic_id,
                                                            "complete",
                                                            Date.now()
                                                        )
                                                    );
                                            });
                                        }}
                                        className="align-self-end"
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                                        Mark as Read
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="subject-container d-flex w-100 h-100">
            <div className="content-container flex-grow-1 p-3 col-md-9">
                <h1 className="mb-3 fw-bolder">{filteredNotes.course_name}</h1>
                {displayedChapter ? (
                    renderChapterContent(displayedChapter)
                ) : (
                    <>
                        {filteredNotes.chapters.map((chapter, chapterIndex) => (
                            <div key={chapterIndex}>
                                <h3 className="mb-3 mt-3 fw-bold">{chapter.chapter_name}</h3>
                                {chapter.topics.map((topic, topicIndex) => (
                                    <div
                                        className="topic vstack"
                                        key={topicIndex}
                                        {...(props.scrollToCh === chapter.chapter_id &&
                                            scrollToTopic === topic.topic_id
                                            ? {
                                                ref: scrollRef,
                                            }
                                            : {})}
                                    >
                                        <div className="hstack justify-content-between">
                                            <h4 className="ps-3">{topic.topic_name}</h4>
                                            <small className="badge badge-pill bg-light text-dark">
                                                {topic.meta?.notes?.stu_status}
                                                {status_icon[topic.meta?.notes?.stu_status] && (
                                                    <FontAwesomeIcon
                                                        className="ms-2"
                                                        icon={status_icon[topic.meta?.notes?.stu_status]}
                                                    />
                                                )}
                                            </small>
                                        </div>

                                        <ul className="ps-5">
                                            {topic.content && topic.content.map((point, pointIndex) => (
                                                <li key={pointIndex} className="mb-1">
                                                    <div dangerouslySetInnerHTML={{ __html: point }} />
                                                </li>
                                            ))}
                                        </ul>
                                        {user.role === "student" && (
                                            <>
                                                {topic.meta?.notes?.stu_status === "complete" ? (
                                                    (topic.meta?.exercises?.stu_status === "not_done" ||
                                                        topic.meta?.faq?.stu_status === "done") && (
                                                        <div
                                                            className="hstack align-items-center text-muted user-select-none alert alert-info p-1 gap-2  "
                                                            style={{ width: "fit-content" }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                className=""
                                                            />
                                                            Test yourself
                                                            <div className="hstack gap-2">
                                                                {topic.meta?.exercises?.stu_status ===
                                                                    "not_done" && (
                                                                        <Link
                                                                            className="link-info"
                                                                            to={makePath([
                                                                                "/ex/do",
                                                                                notes.course_id,
                                                                                chapter.chapter_id,
                                                                                topic.topic_id,
                                                                            ])}
                                                                        >
                                                                            Exercise
                                                                            <FontAwesomeIcon
                                                                                icon={faSquareArrowUpRight}
                                                                                className="ms-2"
                                                                            />
                                                                        </Link>
                                                                    )}
                                                                {topic.meta?.exercises?.stu_status ===
                                                                    "not_done" &&
                                                                    topic.meta?.faq?.stu_status ===
                                                                    "not_done" && (
                                                                        <div className="vr"></div>
                                                                    )}
                                                                {topic.meta?.faq?.stu_status ===
                                                                    "not_done" && (
                                                                        <Link
                                                                            className="link-info"
                                                                            to={makePath([
                                                                                "/faq/do",
                                                                                notes.course_id,
                                                                                chapter.chapter_id,
                                                                                topic.topic_id,
                                                                            ])}
                                                                        >
                                                                            FAQ
                                                                            <FontAwesomeIcon
                                                                                icon={faSquareArrowUpRight}
                                                                                className="ms-2"
                                                                            />
                                                                        </Link>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            updateProgressNotes(
                                                                filteredNotes.course_id,
                                                                chapter.chapter_id,
                                                                topic.topic_id,
                                                                "complete"
                                                            ).then((result) => {
                                                                if (result.data.acknowledged === true)
                                                                    setNotes(
                                                                        commitNotes(
                                                                            notes,
                                                                            chapter.chapter_id,
                                                                            topic.topic_id,
                                                                            "complete",
                                                                            Date.now()
                                                                        )
                                                                    );
                                                            });
                                                        }}
                                                        className="align-self-end"
                                                    >
                                                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                                                        Mark as Read
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </>
                )}
            </div>
            <button className="filter-toggle-btn" onClick={toggleSidebar}>
                <FaFilter />
            </button>
            <div className={`card sidebar p-3 col-md-3 ${isSidebarOpen ? 'open' : ''}`} style={{ width: '300px', overflowY: 'auto' }}>
                <h2>Filters</h2>
                <div className="mb-3">
                    <FormCheck
                        type="radio"
                        id="filter-all"
                        label="All"
                        checked={filters.stu_status === "all"}
                        onChange={() => handleFilterChange("all")}
                    />
                    <FormCheck
                        type="radio"
                        id="filter-complete"
                        label="Complete"
                        checked={filters.stu_status === "complete"}
                        onChange={() => handleFilterChange("complete")}
                    />
                    <FormCheck
                        type="radio"
                        id="filter-incomplete"
                        label="Incomplete"
                        checked={filters.stu_status === "incomplete"}
                        onChange={() => handleFilterChange("incomplete")}
                    />
                </div>
                <h2>Chapters & Topics</h2>
                {notes.chapters.map((chapter, chapterIndex) => (
                    <div key={chapterIndex} className="chapter-sidebar">
                        <FormCheck
                            type="checkbox"
                            id={`chapter-${chapter.chapter_id}`}
                            label={chapter.chapter_name}
                            onChange={() => handleChapterChange(chapter.chapter_id)}
                            checked={selectedChapters.includes(chapter.chapter_id)}
                        />
                        {selectedChapters.includes(chapter.chapter_id) && (
                            <div className="topics-list ps-3">
                                {chapter.topics.map((topic, topicIndex) => (
                                    <FormCheck
                                        key={topicIndex}
                                        type="checkbox"
                                        id={`topic-${topic.topic_id}`}
                                        label={topic.topic_name}
                                        onChange={() => handleTopicChange(topic.topic_id)}
                                        checked={selectedTopics.includes(topic.topic_id)}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default function Subject() {
    const params = useParams();

    return (
        <>
            <Suspense fallback={<Loading show={"loading notes"} />}>
                <Await resolve={getSubjectNotes(params.course_id).catch(console.log)}>
                    <DisplaySubject
                        scrollToCh={params.chapter_id}
                        scrollToTopic={params.topic_id}
                    />
                </Await>
            </Suspense>
        </>
    );
}
