import config from "../json/config";

export function adminOnly(user) {
	if (user) {
		if (user.role == "staff") return true;
		else if (user.role == "student") throw "admin only";
		else noAuth();
	}
	return false;
}
export function noAuth() {
	throw "no auth";
}
export const _url = {
	admin: {
		progress: "/admin/progress",
        users:{
            update:{
                roles:{
                    visToStu:"/admin/users/roles/vistostu",
                }
            }
        },
		allCourse: "/admin/courses",
		allCourseStruct: "/admin/courses_full",
		course: "/admin/course",
		addCourse: "/admin/addcourse",
		replaceCourse: "/admin/replacecourse",
		deleteCourse: "/admin/deletecourse",
		getAllStudents: "/admin/allstudents",
		batches: {
			all: "/admin/batches",
			id: "/admin/batches/id",
			structById: "/admin/batch_struct/id",
			add: "/admin/addbatch",
			replace: "/admin/updatebatch",
			delete: "/admin/deletebatch",
		},
		tracks: {
			all: "/admin/tracks",
			id: "/admin/tracks/id",
			add: "/admin/addtrack",
			replace: "/admin/replacetrack",
			delete: "/admin/deletetrack",
		},
		subjectNotes: "/admin/subjectnotes",
		notes: "/admin/notes",
		addNotes: "/admin/addnotes",
		replaceNotes: "/admin/replacenotes",
		deleteNotes: "/admin/deletenotes",
		addPracticeFAQ: "/faq/add",
		exercises: {
			replace: "/admin/exercises/replace",
			delete: "/admin/exercises/delete",
			add: "/admin/exercises/add",
			view: "/admin/exercises/path",
			getById: "/admin/exercises/id",
		},
		faq: {
			replace: "/admin/faq/replace",
			delete: "/admin/faq/delete",
			add: "/admin/faq/add",
			view: "/admin/faq/path",
			getById: "/admin/faq/id",
		},
		studentResponses: {
			exercises : {
				get: "/student/responses",
				updateStatus: "/student/response/exercise/status"
			}
		}
	},
	nonAdmin: {
		progress: {
			notes: "/progress/notes",
			exercises: "/progress/exercises",
			faq: "/progress/faq",
		},
		subjectNotes: "/subjectnotes",
		notes: "/notes",
		course: "/course",
		allCourse: "/courses",
		allCourseStruct: "/courses_full",

		complexity: "/complexities",
		login: "/login",
		signup:"/signup",
		Profile:"/profile",
		faq: {
			get: "/faq",
			getById: "/faq_id",
		},
		exercises: {
			get: "/exercises",
			getById: "/exercise_id",
		},
	},
};
