import axios from "axios";
import { global_user as user } from "../components/PortalApp";
import config from "../json/config.json";

const axiosWAuth = {
	get: (url, headers) => {
		if (!user) throw "unAuth";
		return axios.get(config.backEnd.hostURL + url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user._id,
				"Access-Control-Allow-Credentials": true,
				...headers,
			},
		});
	},
	post: (url, data, headers) => {
		if (!user) throw "unAuth";

		return axios.post(config.backEnd.hostURL + url, data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user._id,
				"Access-Control-Allow-Credentials": true,
				...headers,
			},
		});
	},
};

export { axiosWAuth };
