import { useState, useEffect } from "react";
import { React, Suspense, useParams, useRef } from "react";
import Loading from "../UtilComponents/Loading";
import { getExercisesToEvaluate, 
         acceptOrRejectStudentResponseExercise } 
                                from "../../util/studentResponceUtil";
import { Await, useAsyncValue, useNavigate } from "react-router-dom";
import { Button, Stack, Table } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { splitArray } from "../../util/generalutil";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileDownload from "../UtilComponents/FileDownload";

export default function EvaluateExercise() {
	return (
		<Suspense fallback={<Loading show={"loading Exercises"} />}>
			<Await resolve={getExercisesToEvaluate().catch(console.log)}>
				<EvaluateExercises />
			</Await>
		</Suspense>
	);
}

    const handleDownload = (filename) => {
      const downloadUrl = `http://localhost:3001/download/${filename}`;
      window.open(downloadUrl, '_blank');
    }

function EvaluateExercises() {
    let exercises = useAsyncValue().data;
    const [exercisesToEvaluate, setExercisesToEvaluate] = useState(exercises);
    const [status, setStatus] = useState("accepted");
    const [remarks, setRemarks] = useState()
    
    let navigate = useNavigate();

    const handlesubmit =(student_id, exercise_id)=>{
        acceptOrRejectStudentResponseExercise(
            student_id, 
            exercise_id, 
            status, 
            remarks).then(result => {
            console.log(result)
            if (result.data!=null) {
                alert("status updated successfully");
                getExercisesToEvaluate()
                .then(response => {
                    console.log('successful:', response.data);
                    setExercisesToEvaluate (response.data)
                  })
                .catch(console.log)
               
            }
        });
    }
    return (
        <div className='exerciseContainer w-100 h-100'>
            <Stack direction='horizontal'
                gap={2}
                className='justify-content-evenly'>
            </Stack>
            <div>records found {exercisesToEvaluate.length}</div>
            <table className="table-bordered mx-2 ">
                        <thead>
                            <tr>
                                <th className="mx-2">Student Name</th>
                                <th className="mx-2">Exercise Description</th>
                                <th className="mx-2">Course Id</th>
                                <th className="mx-2">Uploaded File</th>
                                <th className="mx-2">Accept</th>
                                <th className="mx-2">Reject</th>
                                <th className="mx-2">Remarks</th>
                                <th className="mx-2">Submit</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                        {exercisesToEvaluate.sort((a,b) => b.happend_on - a.happend_on).map((student, stIndex) =>
                (
                    
                                <tr key={stIndex}>
                                    <td>{student.student_info[0].displayName}</td>
                                    <td>{student.exercise_info[0].description}</td>
                                    <td>{student.exercises.course_id}</td>

                                    <td>
                                    {
                                        (student.exercises.attachments != null && student.exercises.attachments[0] != null && student.exercises.attachments[0].length > 0)
                                        && <FileDownload 
                                                studentId={student._id}
                                                exerciseId={student.exercises.exercise_id}
                                            />
                                        }
                                    </td>
                                    <td>
                                        <input type="radio" 
                                            name={`acceptReject_${stIndex}`} 
                                            value="accepted"
                                            onChange={(e) => { setStatus(e.target.value) }}/>
                                    </td>
                                    <td>
                                        <input type="radio"
                                            name={`acceptReject_${stIndex}`} 
                                            value="rejected"
                                            onChange={(e) => { setStatus(e.target.value) }} />
                                    </td>
                                    <td><input type="text" name={`remarks_${stIndex}`}
                                     onChange={(e) => {setRemarks(e.target.value) }} /></td>
                                    <td>
                                        <button type="button" 
                                            onClick={(e) => {
                                                handlesubmit(student._id, student.exercises.exercise_id)
                                            }}>Submit</button>
                                    </td>
                                </tr>
                      
                )
                )
                }
                        </tbody>
                    </table>
            
        </div >
    )
}

function ShowOneExercise(props) {
	return (
		<tr style={{ background: props.bg }} className="tdnobg">
			<td className="small text-muted pe-none user-select-none"></td>
			<td>{props.exercise.description}</td>
			<td>{props.exercise.hint}</td>
		</tr>
	);
}
