import {
	faAngleRight,
	faFilter,
	faGauge,
	faInfoCircle,
	faQrcode,
	faSort,
	faSortDown,
	faSortUp,
	faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { Fragment, Suspense, useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Form, FormControl, FormSelect, Row, Col } from "react-bootstrap";
import { Await, Link, useAsyncValue, useLocation } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import "../../css/PracticeExercise.css";
import { getComplexities } from "../../util/cplxutil";
import { giveQrLink, makePath } from "../../util/generalutil";
import { getExerciseById, getExercises } from "../../util/questionutil";
import { global_user } from "../PortalApp";
import Loading from "../UtilComponents/Loading";
import SelectComplexity from "../UtilComponents/SelectComplexity";
import Share from "../UtilComponents/Share";
import config from "../../json/config.json";
import { axiosWAuth } from "../../util/axiosWAuth";

function QuestionModule(props) {
	const location = useLocation();
	console.log(props.complexityDict, props.exercise);

	let exComplexity = props.complexityDict.find(
		(item) => item.complexity_id == props.exercise.complexity_id
	);

	console.log(exComplexity);
	const [file, setFile] = useState(null);

	useEffect(() => {
		const currentDate = new Date(); // Get current date and time
		console.log("Current URL path:", location.pathname);
		console.log("Current date and time:", currentDate.toLocaleString());
	}, [location]);

	function handleSubmit(e) {
		e.preventDefault();
		if (validateSubmit()) {
			const formData = new FormData();

			// Get the exercise ID from props.exercise
			const exerciseId = props.exercise._id;
			const courseId = props.exercise.course_id;
			const chapterId = props.exercise.chapter_id;
			const topicId = props.exercise.topic_id;
			const studentId = global_user._id;
			// Get the current time
			const submissionTime = new Date().toISOString();

			// Include exercise ID and submission time in the form data
			formData.append("file", file);
			formData.append("studentId", studentId);
			formData.append("courseId", courseId);
			formData.append("chapterId", chapterId);
			formData.append("topicId", topicId);
			formData.append("exerciseId", exerciseId);
			formData.append("timestamp", Date.now());
			formData.append("submissionTime", submissionTime);

			axios
				.post(config.backEnd.hostURL + "/upload", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						"studentid": global_user._id
					},
				})
				.then((response) => {
					console.log("File upload success:", response.data);
					window.alert("Submitted");
					// Make a separate POST request to save exercise ID and submission time
					axiosWAuth
						.post("/student/response", {
							student_id: studentId,
							exercise_id: exerciseId,
							course_id: courseId,
							chapter_id: chapterId,
							topic_id: topicId,
							status: "submitted",
							attachment_file_path: file.name,
							timestamp: Date.now(),
							action: "submitted",
							actionDate: Date.now(),
							actionBy: "student",
							submissionTime,
						})
						.then((studentResponse) => {
							console.log("Exercise submission success:", studentResponse.data);
							props.setArrayEx(prevArray => prevArray.filter(item => item._id != exerciseId));
						})
						.catch((studentError) => {
							console.error("Exercise submission error:", studentError);
						});
				})
				.catch((error) => {
					console.error("File upload error:", error);
				});
		} else {
			return false;
		}
	}

	function handleFileChange(e) {
		setFile(e.target.files[0]);
	}

	function validateSubmit() {
		if (file != null) return true;
	}

	return (
		<Container fluid>
			<Col md={12}>
				<Form
					className={`vstack m-auto gap-3 maxw600 view-${props.view} hover-box`}
					disabled={!validateSubmit()}
					style={{ border: '1px solid grey', borderRadius: '20px', padding: '8px' }}
				>
					<div>
						<Row>
							<Col md={6}>{props.exercise.description}</Col>
							<Col md={2} className="hintCont mt-0">
								{props.exercise.meta?.notes?.stu_status == "complete"
									? "Got stuck?Re-Refer "
									: ""}
								<Link target="_blank"
									to={makePath([
										"/notes/sub",
										props.exercise.course_id,
										props.exercise.chapter_id,
										props.exercise.topic_id,

									])}
								>
									notes <FontAwesomeIcon icon={faSquareArrowUpRight} className="ms-2" />
								</Link>
							</Col>
							<Col>
								<p className="status badge bg-light text-dark align-self-end">
									{props.exercise.stu_response_status == "not found in student response"
										? "not attempted"
										: props.exercise.stu_response_status}
								</p>
							</Col>
							<Col className="shareCont hstack">
								<Col md={2}>
									<div className="hstack gap-2">
										<Share
											text={"Check out this exercise "}
											title={"Share Exercise"}
											url={makePath([
												window.location.origin,
												"ex",
												"do",
												"id",
												props.exercise._id,
											])}
										/>
										<Link
											target="_new"
											className="text-decoration-none text-white"
											to={giveQrLink(
												window.location.origin + "/ex/do/id/" + props.exercise._id
											)}
										>
											<FontAwesomeIcon icon={faQrcode} size="1x" />
										</Link>
									</div>

								</Col>
							</Col>
							<Col md={2} className="mt-0">
								<FontAwesomeIcon icon={faGauge} className="me-1" />
								{exComplexity.complexity_name}
							</Col>
						</Row>
					</div>
					<div className="cont2">
						<Row>
						</Row>
					</div>
					<div>
						<Row>
							<Col md={6}>
								<Col className="text-muted user-select-none assist"></Col>
								{props.exercise.hint && (
									<Alert variant="info" className="p-1 m-0 hintCont">
										<FontAwesomeIcon icon={faInfoCircle} className="me-2 ms-2" />
										{props.exercise.hint}
									</Alert>
								)}
							</Col>
							<Col md={6} className="hintCont">
								<Form.Group
									key={props.exercise._id}
									controlId={props.exercise._id + "file"}
									className="fileHolder"
								>
									{file && file.name}
									<Form.Label className="btn btn-primary mt-0 mb-0">
										{file ? "Change" : "Upload file"}
									</Form.Label>

									<FormControl type="file" hidden onChange={handleFileChange} />
									<Button onClick={handleSubmit} className="hintContsubmit" disabled={!validateSubmit()}>
										Submit Solution
										<FontAwesomeIcon icon={faAngleRight} className=" me-2 ms-2" />
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</div>
				</Form>

			</Col>

		</Container>
	);
}

function QuestionsModule(props) {
	const fullExArray = props.exercises;
	const complexityDict = [...useAsyncValue().data];
	const views = ["none", "full", "compact"];
	const [viewlvl, setViewlvl] = useState(0);

	let [filtByResp, setFiltByResp] = useState(fullExArray);
	let [arrayEx, setArrayEx] = useState(filtByResp);
	const [order, setOrder] = useState(0);
	const [complexity, setComplexity] = useState("");
	const [stu_response_status, setStuRespStatus] = useState(
		fullExArray.length === 0 ? "not found in student response" : ""
	);

	useEffect(() => {
		if (stu_response_status == "")
			setFiltByResp(
				order == 0
					? [...fullExArray]
					: [...fullExArray].sort((a, b) => order * (a.complexity_id - b.complexity_id))
			);
		else if (stu_response_status == "submitted")
			setFiltByResp(
				order == 0
					? [...fullExArray].filter(
						(exercise) => exercise.stu_response_status == stu_response_status
					)
					: [...fullExArray]
						.filter(
							(exercise) => exercise.stu_response_status == stu_response_status
						)
						.sort((a, b) => {
							return order * (a.complexity_id - b.complexity_id);
						})
			);
		else
			setFiltByResp(
				order == 0
					? [...fullExArray].filter(
						(exercise) => exercise.stu_response_status != "submitted"
					)
					: [...fullExArray]
						.filter(
							(exercise) => exercise.stu_response_status == stu_response_status
						)
						.sort((a, b) => {
							return order * (a.complexity_id - b.complexity_id);
						})
			);
	}, [stu_response_status, order]);

	function filterArrayEx(complexity_id) {
		if (complexity_id == "") setArrayEx([...filtByResp]);
		else
			setArrayEx(
				[...filtByResp].filter((exercise) => exercise.complexity_id == complexity_id)
			);
	}

	useEffect(() => {
		filterArrayEx(complexity);
	}, [filtByResp, complexity]);

	useEffect(() => {
		if (arrayEx.length == 0) setViewlvl(0);
		if (arrayEx.length == 1) setViewlvl(1);
		if (arrayEx.length > 1) setViewlvl(2);
		setArrayEx(props.exercises)
	}, [props])

	if (fullExArray.length == 0)
		return (
			<div className="vstack text-center align-items-center justify-content-center">
				<h3>NO</h3>
				<h3>EXERCISES</h3>
				<h3>FOUND</h3>
				<i>It's lonely here</i>
				<Link to="/ex">Try other Exercises</Link>
				<small>Request your Trainer for an exercise at this page</small>
			</div>
		);
	else
		return (
			<>
				<Container fluid>

					<Row className={"exerciseContainer h-100 reversecolumn"} style={{ marginTop: '30px' }}>
						<Col md={10}>
							{views[viewlvl] == "none" && (
								<div className="vstack">
									<h3>Nothing in this criteria</h3>
									<h3>Try another filter</h3>
								</div>
							)}
							{arrayEx.length} exercises found
							{arrayEx.map((exercise, index) => (
								<div style={{ marginTop: '10px' }}>
									<Fragment key={index}>
										<QuestionModule
											exercise={exercise}
											view={views[viewlvl]}
											complexityDict={complexityDict}
											setArrayEx={setArrayEx}
										/>
										{index == arrayEx.length - 1}
									</Fragment>
								</div>
							))}
						</Col>
						<Col md={2}>
							{fullExArray.length > 1 && (
								<div>
									<FontAwesomeIcon icon={faFilter} size="2x" className="me-2" />
									<div>
										<SelectComplexity
											complexityDict={complexityDict}
											complexity={complexity}
											setComplexity={(e) => {
												setComplexity(e);
											}}
										/>
									</div>

									<div style={{ marginTop: '10px' }}>
										<FormSelect

											value={stu_response_status}
											onChange={(e) => setStuRespStatus(e.target.value)}
										>
											<option value="">All</option>
											<option value="submitted">submitted</option>
											<option value="not found in student response">
												not attempted
											</option>
										</FormSelect>
									</div>

									<div>
										{viewlvl == 2 && (
											<div className="d-flex flex-row" style={{ marginTop: '10px' }}>
												<div className="">
													{
														<FontAwesomeIcon
															icon={[faSortUp, faSort, faSortDown][order + 1]}
															size="2x"
															className="me-2"
														/>
													}
												</div>
												<div>
													order
													<Form.Check
														type="radio"
														label="Easy to Hard"
														defaultChecked={order == 1}
														name="order"
														id="eth"
														onClick={() => setOrder(1)}
													/>
													<Form.Check
														type="radio"
														label="Hard to Easy"
														defaultChecked={order == -1}
														name="order"
														id="hte"
														onClick={() => setOrder(-1)}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</Col>
					</Row>
				</Container>
			</>
		);
}

function GetComplexityAndContinue() {
	let exercises = useAsyncValue().data;
	exercises = [exercises].flat(); //makes exercises as an array even if it is a single object
	return (
		<Suspense fallback={<Loading show={"loading complexity"} />}>
			<Await resolve={getComplexities()}>
				<QuestionsModule exercises={exercises} />
			</Await>
		</Suspense>
	);
}

function getExercisesByParams(params) {
	if (params.exercise_id) return getExerciseById(params.exercise_id).catch(console.log);
	if (params.course_id) {
		if (params.chapter_id) {
			if (params.topic_id) {
				return getExercises(params.course_id, params.chapter_id, params.topic_id).catch(
					console.log
				);
			}
			return getExercises(params.course_id, params.chapter_id).catch(console.log);
		}
		return getExercises(params.course_id).catch(console.log);
	}
}

function PracticeExercises(props) {
	const params = {
		course_id: props.courseId,
		chapter_id: props.chapterId,
		topic_id: props.topicId
	}

	return (
		<Container fluid>
			<Row>
				<Col>
					{props.chapterId && props.topicId ? (
						<Suspense fallback={<Loading show={"loading exercise"} />}>
							<Await resolve={getExercisesByParams(params)}>
								<GetComplexityAndContinue />
							</Await>
						</Suspense>
					) : (
						<div className="vstack text-center align-items-center justify-content-center">
							<h6>Please select chapter and topic to load exercises</h6>
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
}

export default PracticeExercises;