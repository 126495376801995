import { axiosWAuth } from "./axiosWAuth";
import { validate } from "./generalutil";
import { _url, adminOnly, noAuth } from "./middleWareUtil";
import { global_user as user } from "../components/PortalApp";
export async function getAllTracks() {
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.tracks.all);
	}
}
export async function getTrack(track_id) {
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.tracks.id + "/" + track_id);
	}
}
export async function addTrack(track) {
	if (adminOnly(user)) {
		if (validateTrack(track)) {
			return await axiosWAuth.post(_url.admin.tracks.add, { track: track });
		} else throw "invalid track";
	}
}
export async function replaceTrack(track_id, track) {
	if (adminOnly(user)) {
		if (validateTrack(track)) {
			let body = {
				track_id: track_id,
				track: track,
			};
			console.log(body);
			return await axiosWAuth.post(_url.admin.tracks.replace, body);
		}
	}
}
export async function deleteTrack(track_id) {
	//TODO:make it delete
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.tracks.delete + "/" + track_id);
	}
}
function validateTrack(track) {
	window.validate = validate;
	return validate(
		track,
		{
			track_id: { type: "string" },
			track_name: { type: "string" },
			courses: { type: "array", childFormat: "string" },
		},
		(e) => {
			console.log(e);
			throw "track not valid";
			return false;
		}
	);
}
