import { axiosWAuth } from "./axiosWAuth";
import { _url, adminOnly, noAuth } from "./middleWareUtil";
import { global_user as user } from "../components/PortalApp";

export async function getAllBatches() {
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.batches.all);
	}
}
export async function getBatchById(batch_id) {
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.batches.id + "/" + batch_id);
	}
}

export async function getBatchStructById(batch_id) {
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.batches.structById + "/" + batch_id);
	}
}

export async function addBatch(batch) {
	if (adminOnly(user)) {
		let body = {
			batch: batch,
		};
		return await axiosWAuth.post(_url.admin.batches.add, body);
	}
}
export async function replaceBatch(batch_id, batch) {
	if (adminOnly(user)) {
		let body = {
			batch_id: batch_id,
			batch: batch,
		};
		return await axiosWAuth.post(_url.admin.batches.replace, body);
	}
}
export async function deleteBatch(batch_id) {
	//todo: replace get iwth delete
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.batches.delete + "/" + batch_id);
	}
}
