import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../json/config.json';

function InstaQandA() {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [images, setImages] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(config.backEnd.hostURL + '/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setImageUrl(response.data.imageURL); // Adjusted key to match backend
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get(config.backEnd.hostURL + '/posts');
      setImages(response.data.posts);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [config.backEnd.hostURL]); // Include hostURL in the dependency array

  return (
    <div>
      <h1>Image Upload</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" name="file" onChange={handleFileChange} />
        <button type="submit">Upload</button>
      </form>
      {imageUrl && (
        <div>
          <h2>Uploaded Image</h2>
          <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%' }} />
        </div>
      )}

      <h2>Images Gallery</h2>
      <div>
        {images.map((image, index) => (
          <img key={index} src={config.backEnd.hostURL + '/socialmediaposts/' + index + '.png'} alt={`Image ${index}`} style={{ maxWidth: '100px', margin: '5px' }} />
        ))}
      </div>
    </div>
  );
}

export default InstaQandA;
