import {
	faArrowDown,
	faArrowUp,
	faChain,
	faCheck,
	faEdit,
	faFilter,
	faRedo,
	faSearch,
	faSort,
	faSortDown,
	faSortUp,
	faTrash,
	faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Suspense, useContext, useEffect, useState } from "react";
import { Button, Form, Stack, Table } from "react-bootstrap";
import { Await, Link, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { getAllCourses } from "../../util/courseutil";
import { getComplexities } from "../../util/cplxutil";
import { makePath, splitN } from "../../util/generalutil";
import { deleteFAQ, getFAQ } from "../../util/questionutil";
import Loading from "../UtilComponents/Loading";
import SelectComplexity from "../UtilComponents/SelectComplexity";
import SelectCourseHierarchy from "../UtilComponents/SelectCourseHierarchy";
import { TimeStr } from "../UtilComponents/CountDown";

export default function SearchFAQ(props) {
	return (
		<Suspense fallback={<Loading show={"loading faq"} />}>
			<Await resolve={getAllCourses().catch(console.log)}>
				<GetComplexitiesAndContinue {...props} />
			</Await>
		</Suspense>
	);
}

function GetComplexitiesAndContinue(props) {
	const courseList = useAsyncValue().data;
	return (
		<Suspense fallback={<Loading show={"loading complexities"} />}>
			<Await resolve={getComplexities()}>
				<SearchForm courseList={courseList} {...props} />
			</Await>
		</Suspense>
	);
}
function SearchForm(props) {
	const complexityDict = useAsyncValue().data;
	const navigate = useNavigate();
	let [courseId, setCourseId] = useState(null);
	let [chapterId, setChapterId] = useState(null);
	let [topicId, setTopicId] = useState(null);
	let [complexity, setComplexity] = useState(null);
	function validateForm() {
		return courseId !== "" && courseId !== null;
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (validateForm()) {
			let path = makePath(["/faq", "search", courseId, chapterId, topicId, complexity])
			console.log(path);
			navigate(path)
		}
	}

	useEffect(() => {
		if (topicId == null || topicId == "") setComplexity("");
	}, [topicId]);
	return (
		<>
			<Form onSubmit={handleSubmit} className="card p-3 gap-3">
				<SelectCourseHierarchy
					courseList={props.courseList}
					courseId={courseId}
					setCourseId={setCourseId}
					chapterId={chapterId}
					setChapterId={setChapterId}
					topicId={topicId}
					setTopicId={setTopicId}
					autoFocus
				/>

				<SelectComplexity
					complexityDict={complexityDict}
					disabled={topicId == "" || topicId == null}
					complexity={complexity}
					setComplexity={setComplexity}
				/>
				<Button type="submit" variant="primary" disabled={!validateForm()}>
					<FontAwesomeIcon className="me-2" icon={faSearch} />
					Search
				</Button>
			</Form>
		</>
	);
}
///////////////////////////////////////
//above code is to just search

//below code is to show results
/////////////////////////////////////
function deleteThisEx(id, reload) {
	deleteFAQ(id).then((results) => {
		console.log(results);
		if (results.data != null && results.status == 200) {
			alert("deleted");
			reload();
			//reload contents of table
		}
	});
}

function ShowOneFAQ({ faq, complexityDict, bg, user, reload }) {
	let params = useParams();
	let exComplexity = {
		...complexityDict.find((item) => item.complexity_id == faq.complexity_id),
	};
	console.log(exComplexity);

	return (
		<tr style={{ background: bg }} className="tdnobg">
			<td className="small text-muted pe-none user-select-none">
				<Stack style={{ fontSize: ".7em" }}>
					{splitN(faq._id, 6).map((item, index) => (
						<span key={index}>{item}</span>
					))}
				</Stack>
			</td>
			<td>{faq.description}</td>
			<td>{faq.hint}</td>
			<td>
                <TimeStr time={faq.timeGiven} />
			</td>

			<td>
				<div className="vstack ">
					{faq.options?.length > 0 &&
						faq.options.map((opt, optIndex) => (
							<Fragment key={optIndex}>
								{optIndex == faq.answerIndex ? (
									<div className="hstack">
										<span style={{ fontSize: "13px" }}>{opt}</span>
										<FontAwesomeIcon icon={faCheck} />
									</div>
								) : (
									<div className="hstack text-muted">
										<span style={{ fontSize: "11px" }}>{opt}</span>
									</div>
								)}
							</Fragment>
						))}
				</div>
			</td>
			{params.chapter_id == null && <td>{faq.chapter_id}</td>}
			{params.topic_id == null && <td>{faq.topic_id}</td>}
			<td>{exComplexity.complexity_name}</td>
			{user.role == "staff" && (
				<>
					<td>
						<Link to={"/faq/edit/" + faq._id} relative="route">
							<FontAwesomeIcon icon={faEdit} />
						</Link>
					</td>
					<td>
						<Button variant="danger" onClick={() => deleteThisEx(faq._id, reload)}>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					</td>
				</>
			)}
			{user.role == "student" && (
				<td>
					<Link to={"/faq/do/id/" + faq._id}>
						<FontAwesomeIcon icon={faUpRightFromSquare} />
					</Link>
				</td>
			)}
		</tr>
	);
}
function ManageAllFAQ(props) {
	let user = useContext(UserContext);
	let params = useParams();
	let navigate = useNavigate();
	const [fullFAQArray, setFullFAQArray] = useState(props.faq);

	function reload() {
		getFAQByParams(params)
			.then((results) => {
				setFullFAQArray(results.data);
				setComplexity("");
				if (order == 0) {
					setArrayFAQ([...results.data]);
				} else
					setArrayFAQ(
						[...results.data].sort(
							(a, b) => order * (a.complexity_id - b.complexity_id)
						)
					);
			})
			.catch(console.log);
	}

	const complexityDict = [...useAsyncValue().data];
	const [complexity, setComplexity] = useState("");
	let [arrayFAQ, setArrayFAQ] = useState(fullFAQArray);
	const [order, setOrder] = useState(0);

	useEffect(() => {
		setArrayFAQ([...arrayFAQ].sort((a, b) => order * (a.complexity_id - b.complexity_id)));
	}, [order]);

	function filterArrayEx(complexity_id) {
		if (complexity_id == "")
			setArrayFAQ(
				order == 0
					? [...fullFAQArray]
					: [...fullFAQArray].sort((a, b) => order * (a.complexity_id - b.complexity_id))
			);
		else
			setArrayFAQ(
				order == 0
					? [...fullFAQArray].filter((faq) => faq.complexity_id == complexity_id)
					: [...fullFAQArray]
							.filter((faq) => faq.complexity_id == complexity_id)
							.sort((a, b) => {
								return order * (a.complexity_id - b.complexity_id);
							})
			);
	}
	useEffect(() => {
		filterArrayEx(complexity);
	}, [complexity]);

	if (fullFAQArray == null)
		return (
			<>
				FAQ on this topic are to be added soon
				<Loading show="..." />
			</>
		);

	return (
		<div className="faqContainer w-100 h-100">
			<Stack direction="horizontal" gap={2} className="justify-content-between">
				<Button onClick={() => reload()}>
					<FontAwesomeIcon icon={faRedo} />
				</Button>
				{params.complexity_id == null && (
					<>
						<FontAwesomeIcon icon={faFilter} size="2x" />
						<div>
							<SelectComplexity
								complexityDict={complexityDict}
								complexity={complexity}
								setComplexity={setComplexity}
							/>
						</div>

						<div className="vr" />
						<FontAwesomeIcon
							icon={[faSortUp, faSort, faSortDown][order + 1]}
							size="2x"
						/>
						<div>
							<Form.Check
								type="radio"
								label="Ascending"
								name="order"
								onClick={() => setOrder(1)}
							/>
							<Form.Check
								type="radio"
								label="Descending"
								name="order"
								onClick={() => setOrder(-1)}
							/>
						</div>
					</>
				)}

				<Button
					onClick={() => {
						navigate("/faq/search");
					}}
				>
					<FontAwesomeIcon icon={faSearch} />
				</Button>
			</Stack>
			<div className="text-muted"> records found:{fullFAQArray.length}</div>
			<div className="text-muted">matching filter:{arrayFAQ.length}</div>

			<Table>
				<thead>
					<tr>
						<th>_id</th>
						<th>description</th>
						<th>hint</th>
						<th>time</th>
						<th>options</th>
						{params.chapter_id == null && <th>chapter_id</th>}
						{params.topic_id == null && <th>topic_id</th>}
						<th className="d-flex align-items-center">
							complexity_id
							{order == 1 && (
								<FontAwesomeIcon icon={faArrowDown} onClick={() => setOrder(-1)} />
							)}
							{order == -1 && (
								<FontAwesomeIcon icon={faArrowUp} onClick={() => setOrder(1)} />
							)}
						</th>
						{user.role == "staff" && (
							<>
								<th className="">edit</th>
								<th className="">delete</th>
							</>
						)}
						{user.role == "student" && (
							<>
								<th>do</th>
							</>
						)}
					</tr>
				</thead>
				<tbody>
					{arrayFAQ.map((faq, exIndex) => (
						<ShowOneFAQ
							bg={
								["var(--bs-info-bg-subtle)", "var(--bs-danger-bg-subtle)"][
									exIndex % 2
								]
							}
							key={exIndex}
							faq={faq}
							user={user}
							complexityDict={complexityDict}
							reload={reload}
						/>
					))}
				</tbody>
			</Table>
		</div>
	);
}
function GetComplexityAndContinue() {
	let faq = useAsyncValue().data;
	return (
		<Suspense fallback={<Loading show={"loading complexity"} />}>
			<Await resolve={getComplexities()}>
				<ManageAllFAQ faq={faq} />
			</Await>
		</Suspense>
	);
}
export function SearchResultsFAQ() {
	const params = useParams();

	return (
		<>
			<Suspense fallback={<Loading show={"loading FAQ"} />}>
				<Await resolve={getFAQByParams(params).catch(console.log)}>
					<GetComplexityAndContinue />
				</Await>
			</Suspense>
		</>
	);
}
function getFAQByParams(params) {
	if (params.course_id) {
		if (params.chapter_id) {
			if (params.topic_id) {
				if (params.complexity_id) {
					return getFAQ(
						params.course_id,
						params.chapter_id,
						params.topic_id,
						params.complexity_id
					).catch(console.log);
				}
				return getFAQ(params.course_id, params.chapter_id, params.topic_id).catch(
					console.log
				);
			}
			return getFAQ(params.course_id, params.chapter_id).catch(console.log);
		}
		return getFAQ(params.course_id);
	}
}
