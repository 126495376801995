import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import "../../css/Signup.css"
import config from '../../json/config.json';
import { _url } from '../../util/middleWareUtil';
import { Row, Col} from 'react-bootstrap';

const Signup = () => {
  const routerNavigate = useNavigate(); 
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mobileno, setMobileNo] = useState('');

  const [isSignedUp, setIsSignedUp] = useState(false);

  const handleSignup = () => {
    const details = {
      "username": username,
      "email": email,
      "password": password,
      "confirmPassword": confirmPassword,
      "mobileNo": mobileno,
     
    };

    axios.post(config.backEnd.hostURL+ _url.nonAdmin.signup, details, 
      { headers: { "Content-Type": "application/json" } })
      .then(response => {
        console.log('Signup successful:', response.data);
        setIsSignedUp(true);
        routerNavigate('/login'); 
      })
      .catch(error => {
        console.error('Signup failed:', error);
      });
  }

  useEffect(() => {
    if (isSignedUp) {
      routerNavigate('/login'); 
    }
  }, [isSignedUp]);

  if (isSignedUp) {
    return null; 
  }

  return (
    <div className="signup-container">
      <div className="container">
       <div> <h2>Register</h2></div>
       <Row>
        <Col sm={12} md={12}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <input
          type="mobile no"
          placeholder="mobile no"
          value={mobileno}
          onChange={(e) => setMobileNo(e.target.value)}
          required
        />
        </Col>
       
        </Row>
        <button onClick={handleSignup}>Signup</button>
      </div>
    </div>
  );
};

export default Signup;