import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

export default function Contactus() {
	return (
		<div className="vstack card border-3 border-secondary rounded rounded-5 p-3 m-5 align-self-center align-items-center justify-content-evenly">
			<h1>Contact Us</h1>
			<h3 className="w-100 hstack gap-3">
				<FontAwesomeIcon icon={faPhone}/>
				<span>Phone No: </span> 
				<Link to="tel:+917702587246" target="_new">
					7702587246
				</Link>
			</h3>
			<h3 className="w-100 hstack gap-3">
				<FontAwesomeIcon icon={faEnvelope}/>
				<span>Email:</span>
				<Link to="mailto:info@edatoz.com" target="_new">
					info@edatoz.com
				</Link>
			</h3>
		</div>
	);
}
