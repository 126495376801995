import React, { Suspense, useState } from "react";
import { Button, Form, FormCheck, FormControl, ProgressBar, Tab, Tabs } from "react-bootstrap";
import { Await, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import { getBatchStructById } from "../../util/batchutil";
import Loading from "../UtilComponents/Loading";
import { afterSplice } from "../../util/generalutil";
import { updateBatchProgress } from "../../util/progressutil";
function DisplayTopic({ topic, setTopic }) {
	let completed = topic.meta.status == "completed";
	function setCompleted(condition) {
		setTopic({
			...topic,
			meta: {
				...topic.meta,
				status: condition ? "completed" : "incomplete",
				on: condition ? on : topic.meta.on,
				scheduled: condition ? topic.meta.scheduled : scheduled,
			},
		});
	}
	function setOn(date) {
		setTopic({
			...topic,
			meta: {
				...topic.meta,
				on: date,
			},
		});
	}
	function setScheduled(date) {
		setTopic({
			...topic,
			meta: {
				...topic.meta,
				scheduled: date,
			},
		});
	}

	const scheduled = topic.meta.scheduled || 0;
	// suggest tomorrow for scheduled ||Date.now() + 24 * 60 * 60 * 1000;

	const on = topic.meta.on || Date.now();
	/////////////////////
	/////
	/////
	/////
	/////
	///// how to make user set  the same date if the  scheduled date changes
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	/////
	////////////////////////////////////
	return (
		<div className="topic w-100 hstack justify-content-between">
			<div className="topic_name">{topic.topic_name}</div>
			<div className="hstack gap-4 details">
				<FormCheck
					label="completed by me"
					checked={completed}
					onChange={(e) => setCompleted(e.target.checked)}
				/>

				{completed ? (
					<div className="on vstack">
						<span>on:</span>
						<FormControl
							type="date"
							value={new Date(on).toISOString().slice(0, 10)}
							onChange={(e) => setOn(new Date(e.target.value).valueOf())}
						/>
					</div>
				) : (
					<div className="schedule vstack">
						<span>scheduled:</span>
						<FormControl
							type="date"
							value={new Date(scheduled).toISOString().slice(0, 10)}
							onInput={(e) => setScheduled(new Date(e.target.value).valueOf())}
						/>
					</div>
				)}
				<div className="topic_id text-muted small">~{topic.topic_id}</div>
			</div>
		</div>
	);
}
function DisplayChapter({ chapter, setChapter }) {
	function selectAll(condition) {
		if (condition)
			setChapter({
				...chapter,
				topics: chapter.topics.map((topic) => ({
					...topic,
					meta: { ...topic.meta, status: "completed" },
				})),
			});
		else
			setChapter({
				...chapter,
				topics: chapter.topics.map((topic) => ({
					...topic,
					meta: { ...topic.meta, status: "incomplete" },
				})),
			});
	}
	return (
		<div className="chapter vstack">
			<div className="hstack justify-content-between">
				<div className="chapter_name h5 hstack align-items-center gap-2">
					<FormCheck
						aria-label="select all"
						indeterminate={
							chapter.topics.some((t) => t.meta.status == "completed") &&
							!chapter.topics.every((t) => t.meta.status == "completed")
						}
						checked={chapter.topics.every((t) => t.meta.status == "completed")}
						onChange={(e) => selectAll(e.target.checked)}
					/>

					{chapter.chapter_name}
				</div>
				<div className="chapter_id text-muted small">~{chapter.chapter_id}</div>
			</div>
			<div className="vstack topicCont">
				{chapter.topics.map((topic, i) => (
					<div className="hstack eachTopic align-items-center">
						<div className={"vr " + (i == chapter.topics.length - 1 ? "h-50" : "")} />
						<hr style={{ width: "1em" }} />

						<DisplayTopic
							topic={topic}
							key={i}
							setTopic={(e) =>
								setChapter({
									...chapter,
									topics: afterSplice(chapter.topics, i, 1, e),
								})
							}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
function DisplayCourse({ course, setCourse }) {
	let completedTopics = course.chapters.map((ch) =>
		ch.topics.filter((t) => t.meta.status == "completed")
	);
	const allTopics = course.chapters.map((ch) => ch.topics);
	return (
		<div className="course vstack ">
			<div className="hstack text-muted small align-self-center justify-content-between w-100">
				<div></div>
				<div className="course_id">~{course.course_id}</div>
				<div className="d-flex flex-column align-items-center">
					<span>
						{completedTopics.flat().length}/{allTopics.flat().length}
					</span>
					<ProgressBar
						now={completedTopics.flat().length/allTopics.flat().length*100}
						label={"topics completed"}
                        visuallyHidden
					/>
				</div>
			</div>

			<div className="vstack">
				{course.chapters.map((ch, i) => (
					<div className="hstack eachChapter align-items-start">
						<div className={"vr " + (i == course.chapters.length - 1 ? "h-100" : "")} />
						<hr style={{ width: "1em" }} />
						<DisplayChapter
							chapter={ch}
							key={i}
							setChapter={(e) =>
								setCourse({
									...course,
									chapters: afterSplice(course.chapters, i, 1, e),
								})
							}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
function DisplayProgress() {
	const batchData = useAsyncValue().data;
	const [batch, setBatch] = useState({ ...batchData });
	const navigate = useNavigate();
	console.log(batch);
	function validate() {
		if (!batch.courses) return false;

		return true;
	}
	function handleSubmit(e) {
		e.preventDefault();
		if (validate()) {
			updateBatchProgress(batch).then((e) => {
				if (e.data.acknowledged) {
					alert("batch updated successfully");
					navigate(-1);
				}
			});
		}
	}
	return (
		<Form
			onSubmit={handleSubmit}
			className=" w-100 h-100 justify-content-evenly overflow-y-auto"
		>
			<div className="static">
				<div className="hstack justify-content-between">
					<div className="batch_name h4">{batch.batch_name}</div>
					<div className="batch_id text-muted small">~{batch._id}</div>
				</div>
				<div className="hstack justify-content-between">
					<div className="vstack">
						<span>from</span>
						<div className="batch_start">{new Date(batch.start).toDateString()}</div>
					</div>
					<div className="vstack">
						<span>expected to be ended at</span>
						<div className="batch_expectedEnd">
							{new Date(batch.expectedEnd).toDateString()}
						</div>
					</div>
				</div>
				<div className="hstack justify-content-between">
					<div className="track_name h5">{batch.track_name}</div>
					<div className="track_id text-muted small">~{batch.track_id}</div>
				</div>
			</div>
			<hr />{" "}
			<Tabs defaultActiveKey={0} id="uncontrolled-tab-example" className="mb-3" fill>
				{batch.courses.map((course, courseIndex) => (
					<Tab eventKey={courseIndex} key={courseIndex} title={course.course_name}>
						<DisplayCourse
							key={courseIndex}
							course={course}
							setCourse={(e) =>
								setBatch({
									...batch,
									courses: afterSplice(batch.courses, courseIndex, 1, e),
								})
							}
						/>
					</Tab>
				))}
			</Tabs>
			<div className="hstack w-100 align-items-center justify-content-center">
				<Button
					disabled={!validate()}
					variant="primary"
					className="align-self-center"
					type="submit"
				>
					Update Progress
				</Button>
			</div>
		</Form>
	);
}
function UpdateProgress() {
	const params = useParams();
	return (
		<Suspense fallback={<Loading show="loading batch progress" />}>
			<Await resolve={getBatchStructById(params.batch_id)}>
				<DisplayProgress />
			</Await>
		</Suspense>
	);
}

export default UpdateProgress;
