import React, { PureComponent } from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
function Tracking() {
const data = [
  {
    name: 'Login',
    uv: 100,
    pv: 100,
    amt: 100,
  },
  {
    name: 'Signup',
    uv: 30,
    pv: 30,
    amt: 30,
  },
  {
    name: 'Dashboard',
    uv: 100,
    pv: 100,
    amt: 100,
  },
  {
    name: 'FAQs',
    uv: 25,
    pv: 25,
    amt: 25,
  },
  {
    name: 'Exercises',
    uv: 60,
    pv: 60,
    amt: 60,
  },
  {
    name: 'Notes',
    uv: 75,
    pv: 75,
    amt: 75,
  },
];


    return (
        <Container fluid>
        <Row>
            <Col md={1}></Col>
            <Col md={6} className="bg-info p-3">
                <center><h3>Progress</h3></center>
                <BarChart
                    width={550}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={20}
                >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="pv" fill="#8884d8" background={{ fill: '#eee' }} />
                </BarChart>

            </Col>
        </Row>
    </Container>
    );
  }
export default Tracking;