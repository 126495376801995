import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { strNotNull } from '../../util/generalutil'
import '../../css/Share.css'
function Share(props) {
    return (
        <Button className='share bg-transparent m-0 p-0' onClick={() => {
            navigator.share({
                title: strNotNull(props.title),
                text: strNotNull(props.text),
                url: strNotNull(props.url)
            })
        }}>
            <FontAwesomeIcon icon={faShareNodes} />
        </Button>
    )
}

export default Share