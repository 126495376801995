import React, { Suspense } from "react";
import { Await, Link, useAsyncValue, useParams } from "react-router-dom";
import { Alert, Accordion, Button } from "react-bootstrap";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteCourse, getAllCoursesStruct } from "../../util/courseutil";
import { makePath } from "../../util/generalutil";
import Loading from "../UtilComponents/Loading";

function ViewCourses() {
	let params = useParams();
	return (
		<div className="SearchTracks position-relative w-100 h-100">
			<Suspense fallback={<Loading show={"loading tracks"} />}>
				<Await resolve={getAllCoursesStruct(params).catch(console.log)}>
					<DisplayCourses />
				</Await>
			</Suspense>
			{/* <Link
				to="/courses/add"
				className="btn btn-primary align-self-end position-absolute m-3 bottom-0 end-0"
			>
				<FontAwesomeIcon icon={faAdd} />
			</Link> */}
		</div>
	);
}

function handleDelete(course_id) {
	if (window.confirm("Are you sure you want to delete this course?")) {
		if (window.confirm("Really?")) {
			deleteCourse(course_id).then((results) => {
				console.log(results);
				if (results.data != null && results.status == 200) {
					alert("deleted");
					window.location.reload();
				}
			});
		}
	}
}

function ViewEachCourse({ course }) {
	return (
		<Accordion>
		<div
			className="course card p-5 rounded-3 h-100 hstack justify-content-between   "
			
		>
			<div className="courseStructure overflow-y-auto">
				<h2 className="courseId text-center">{course.course_id}</h2>
				<h2 className="courseName">{course.course_name}</h2>
				<div className="hstack">
					<div className="vstack">
						{course.chapters.map((chap, chapIndex) => (
							<div
								className="chapterCont hstack ms-3  align-items-start"
								key={chapIndex}
							>
								<div
									className="vr"
									style={
										chapIndex < course.chapters.length - 1
											? {}
											: { height: "1em" }
									}
								></div>

								<hr style={{ width: "1em" }}></hr>
								<div className="chapter w-100">
									<div className="hstack justify-content-between">
										<h3 className="chapter_name">{chap.chapter_name}</h3>

										<div className="chapter_id">~{chap.chapter_id}</div>
									</div>
									{chap.topics.map((topic, topicIndex) => (
										<div className="topicCont ms-2 hstack" key={topicIndex}>
											<div
												className="vr"
												style={
													topicIndex < chap.topics.length - 1
														? {}
														: { height: "1em" }
												}
											></div>
											<hr style={{ width: "1em" }}></hr>
											<div className="topic w-100">
												<div className="hstack justify-content-between">
													<div className="topic_name">
														{topic.topic_name}
													</div>
													<div className="topic_id">
														~{topic.topic_id}
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="actions d-flex align-items-center justify-content-evenly">
				<Link
					className="d-inline-flex align-items-center max-w-min gap-2 btn btn-primary"
					to={makePath(["/courses", "edit", course.course_id])}
					relative="route"
				>
					<FontAwesomeIcon icon={faEdit} />
					Edit
				</Link>
				<Button
					className="d-inline-flex align-items-center max-w-min gap-2"
					variant="danger"
					onClick={() => {
						handleDelete(course.course_id);
					}}
				>
					<FontAwesomeIcon icon={faTrash} />
					Delete
				</Button>
			</div>
		</div>
		</Accordion>
	);
}

function DisplayCourses() {
	let courses = useAsyncValue().data;
	console.log(courses);
	return (
		<>
		
			<div className="h-100 d-flex vstack">
				
				{courses.map((course, courseIndex) => (
					<div className="h-100 p-5" key={courseIndex}>
						<ViewEachCourse course={course} />
					</div>
				))}
			</div>
			
			{
				courses.length == 0 &&
				// <p className="text-center">No courses found</p>
				<Alert variant="primary">
					<Alert.Heading>No Courses found!</Alert.Heading>
					<p>
						There are no courses currently availale in the system.
					</p>
					<hr />
					You are the lucky one to <Alert.Link href="/courses/add">add the first course</Alert.Link>
				</Alert>
			}
		</>
	);
}

export default ViewCourses;