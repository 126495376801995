
import Carousel from "./carousel";

function TutorAnnotations() {
  const slidesData = [
    {
      caption: "React Hooks",
    },
    {
      caption: "What is a Hook?",
      Info: " A hook is a special function that allows you to use state and other React features in functional components.React introduced hooks in version 16.8 to enable functional components to have stateful logic, side effects, and more."
    },
    {
      caption: "Why React Hooks?",
      Info : "React Hooks are a way to use state and other React features in functional components.They allow functional components to do everything that class components could do.Hooks make it easier to manage state, handle side effects, and reuse logic in simpler, functional components."
    },
    {
      caption: "useState",
      Info:"useState is a hook in React that enables functional components to have state.It returns an array with two elements: the current state value and a function that lets you update it."
    },
    {
      caption: "useEffect",
      Info:"useEffect is a hook in React that performs side effects in functional components.It takes two arguments: a function that contains the code for the side effect and an optional array of dependencies."
    },
    {
      caption: "useContext",
      Info:"useContext is a hook in React that provides a way for functional components to access the value of a React context."
    },
    {
      caption: "useReducer",
      Info:"useReducer is a hook in React that is used for managing state in functional components.It takes a reducer function and an initial state as arguments and returns the current state and a dispatch function."
    },
    {
      caption: "useRef",
      Info:"useRef is a hook in React used for accessing and interacting with the DOM, holding mutable values, or persisting values across renders without causing re-renders."
    },
    {
      caption: "useCallback",
      Info:"useCallback is a React Hook that is used to memoize functions, especially those that are passed as dependencies to child components. It helps optimize performance by preventing unnecessary re-creation of functions on each render."
    },
    {
      caption: "useMemo",
      Info:"useMemo is a React Hook used for memoization, specifically for memoizing the result of a computation. It helps optimize performance by preventing unnecessary recalculations of values on each render."
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const InfoStyle = {
    fontSize: "20px",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            padding: "20px 20px",
          }}
        >
          <Carousel
            data={slidesData}
            width="850px"
            height="400px"
            captionStyle={captionStyle}
            InfoStyle={InfoStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="top"
            InfoPosition="center"
            dots={true}
            slideBackgroundColor="#092658"
            showNavBtn={true}
            style={{
              textAlign: "center",
              maxWidth: "850px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TutorAnnotations;