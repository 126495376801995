import { global_user as user } from "../components/PortalApp";
import { axiosWAuth } from "./axiosWAuth";
import { _url, adminOnly, noAuth } from "./middleWareUtil";
export async function getExercisesToEvaluate() {
	if (user) {
		if (user.role === "staff") {
         return await axiosWAuth.get(_url.admin.studentResponses.exercises.get);
        }
        else noAuth();
	} else noAuth();
}
export async function getStuResponseExercise(){
        let res = await axiosWAuth.get(_url.admin.studentResponses.exercises.get)
        console.log(res)
}

export async function acceptOrRejectStudentResponseExercise(
        student_id, 
        exercise_id, 
        status, 
        remarks,
        timestamp = Date.now() ) {
	if (adminOnly(user)) {
                let body = {
                        student_id: student_id,
                        exercise_id: exercise_id,
                        status: status,
                        remarks: remarks,
                        timestamp: timestamp
                    }

                return await axiosWAuth.post(_url.admin.studentResponses.exercises.updateStatus, body, {
                        headers: {
                                "Content-Type": "application/json",
                        },
                });
	}
}
