import { faAdd, faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useState, useMemo, useEffect, useRef } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { Await, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import {
    addNoteForCourse,
    getAllCourses,
    getSubjectNotes,
    replaceNotesForCourse,
} from "../../util/courseutil";
import Loading from "../UtilComponents/Loading";
import SelectCourseHierarchy from "../UtilComponents/SelectCourseHierarchy";
import { withoutElAtIndex } from "../../util/generalutil";
import JoditEditor from "jodit-react";

function AddNoteByCourseList() {
    const editor = useRef(null);

    const courseList = useAsyncValue().data;
    let navigate = useNavigate();
    let [courseId, setCourseId] = useState("");
    let [chapterId, setChapterId] = useState("");
    let [topicId, setTopicId] = useState("");
    let [invokeLoadCourseRef, setInvokeLoadCourseRef] = useState(false);
    let [previewNote, setPreviewNote] = useState(false);
    let [noteList, setNoteList] = useState([]);
    let params = useParams();

    useMemo(() => {
        if (params) {
            if (params.mode === "edit") {
                getSubjectNotes(params.course_id, params.chapter_id, params.topic_id).then(
                    function (value) {
                        setCourseId(value.data.course_id);
                        setInvokeLoadCourseRef(true);
                        setChapterId(value.data.chapters[0].chapter_id);
                        setTopicId(value.data.chapters[0].topics[0].topic_id);
                        setNoteList(value.data.chapters[0].topics[0].content);
                    }
                );
            }
        }
    }, [params]);

    function extractNotes(newContent) {
        let noteArray = newContent.split("\n");
        while (noteArray.length > 0 && noteArray[0].trim() === "") {
            noteArray.shift();
        }
        setNoteList(noteArray);
    }

    function validateForm() {
        return (
            courseId !== "" &&
            chapterId !== "" &&
            topicId !== "" &&
            courseId !== null &&
            chapterId !== null &&
            topicId !== null &&
            courseId !== "select" &&
            chapterId !== "select" &&
            topicId !== "select" &&
            noteList.length !== 0 &&
            !(noteList.length === 1 && noteList[0].trim() === "")
        );
    }

    function beautify() {
        setNoteList(
            noteList.filter((e) => {
                return e !== "";
            })
        );
    }

    function preview() {
        setPreviewNote(!previewNote);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (validateForm()) {
            beautify();
            if (params.mode === "edit") {
                replaceNotesForCourse(courseId, chapterId, topicId, noteList).then((result) => {
                    alert("note updated successfully");
                    navigate(-1);
                    if (result.data.acknowledged) {
                        setPreviewNote(false);
                        setNoteList([]);
                    }
                });
            } else if (params.mode === "add") {
                addNoteForCourse(courseId, chapterId, topicId, noteList).then((result) => {
                    alert("note added successfully");
                    if (result.data.acknowledged) {
                        setPreviewNote(false);
                        setNoteList([]);
                    }
                });
            }
        } else {
            alert("please fill all the fields");
        }
    }

    const config = {
        placeholder : "Enter note..."
    }

    return (
        <Form
            variant={"dark"}
            onSubmit={handleSubmit}
            // style={{ maxWidth: "600px" }}
            className="h-100 w-100 m-3 card p-3 d-flex justify-content-evenly"
        >
            <SelectCourseHierarchy
                // disabled={params.mode === "edit"}
                courseList={courseList}
                invokeLoadCourseRef={invokeLoadCourseRef}
                setInvokeLoadCourseRef={setInvokeLoadCourseRef}
                courseId={courseId}
                setCourseId={setCourseId}
                chapterId={chapterId}
                setChapterId={setChapterId}
                topicId={topicId}
                setTopicId={setTopicId}
                autoFocus
            />

            {noteList.length > 0 && previewNote && (
                <div className="d-flex flex-column p-2 overflow-y-auto gap-2 rounded">
                    {noteList.map((note, index) => (
                        <div
                            key={index}
                            className="p-1 d-flex justify-content-between align-items-center"
                            style={{
                                outline:
                                    "2px solid rgb(" +
                                    Math.floor(Math.random() * 255) +
                                    "," +
                                    Math.floor(Math.random() * 255) +
                                    "," +
                                    Math.floor(Math.random() * 255) +
                                    ")",
                                borderRadius: "5px",
                            }}
                        >
                            <span>{note}</span>
                            <Button
                                variant="danger"
                                className="ms-2"
                                onClick={() => {
                                    if (noteList.length === 1) {
                                        setNoteList([]);
                                        setPreviewNote(false);
                                    }
                                    setNoteList(withoutElAtIndex(noteList, index));
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </div>
                    ))}
                </div>
            )}

            {(!previewNote || !(noteList.length > 0)) && (
            //     <FormControl
            //     as={"textarea"}
            //     placeholder="Enter note"
            //     className="h-50"
            //     onChange={extractNotes}
            //     value={noteList.join("\n")}
            // />

                <JoditEditor
                    ref={editor}
                    value={noteList.join("\n")}
                    onChange={extractNotes}
					className="mb-3 mt-3"
					style={{overflowX: 'hidden'}}
                />
            )}

            <Stack direction="horizontal" gap={2} className="justify-content-evenly">
                {noteList.length > 0 && (
                    <Button
                        onClick={() => {
                            beautify();
                            preview();
                        }}
                    >
                        {previewNote ? (
                            <>
                                <FontAwesomeIcon icon={faEdit} className="ms-2 me-2" />
                                Edit
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faEye} className="ms-2 me-2" />
                                Preview
                            </>
                        )}
                    </Button>
                )}

                {previewNote && (
                    <Button type="submit" disabled={!validateForm()}>
                        <FontAwesomeIcon icon={faAdd} className="ms-2 me-2" />
                        <span className="text-capitalize">
                            {params.mode}
                            &nbsp;Note
                        </span>
                    </Button>
                )}
            </Stack>
        </Form>
    );
}

function AddNote() {
    return (
        <Suspense fallback={<Loading show={"loading courses"} />}>
            <Await resolve={getAllCourses().catch(console.log)}>
                <AddNoteByCourseList />
            </Await>
        </Suspense>
    );
}

export default AddNote;
