import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense } from "react";
import { Button, Table } from "react-bootstrap";
import { Await, Link, useAsyncValue, useParams } from "react-router-dom";
import { deleteTrack, getAllTracks } from "../../util/trackutil";
import Loading from "../UtilComponents/Loading";

function SearchTrack() {
	let params = useParams();
	return (
		<Suspense fallback={<Loading show={"loading tracks"} />}>
			<Await resolve={getTracksByParams(params)}>
				<DisplayTracksActions />
			</Await>
		</Suspense>
	);
}

//below code is to show
/////////////////////////////////////
function handleDelete(track_id) {
	if (window.confirm("Are you sure you want to delete this track?")) {
		deleteTrack(track_id)
			.then((results) => {
				console.log(results);
				if (results.data != null) {
					alert("deleted");
					window.location.reload();
				}
			})
			.catch(console.log);
	}
}
function DisplayTracksActions() {
	let tracks = useAsyncValue().data;
	console.log(tracks);
	return (
		<div className="w-100 tracks position-relative w-100 mh-100 vstack align-items-center justify-content-center">
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Track ID</th>
						<th>Track Name</th>
						<th>Courses</th>
						<th>Edit</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					{tracks.map((track, trackIndex) => (
						<tr key={trackIndex}>
							<td>{track.track_id}</td>
							<td>{track.track_name}</td>
							<td>
								{track.courses
									? track.courses.map((course, ind) => (
											<span key={ind}>
												<span title={course.course_id}>
													{course.course_name}
												</span>
												{ind < track.courses.length - 1 && ","}
											</span>
									  ))
									: "no courses"}
							</td>
							<td>
								<Link
									className="hstack btn btn-primary"
									to={"/tracks/edit/" + track.track_id}
									relative="route"
								>
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</td>
							<td>
								<Button
									variant="danger"
									className="hstack"
									onClick={() => handleDelete(track.track_id)}
								>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<Link
				className="add position-absolute bottom-0 end-0 m-3 btn btn-primary"
				to={"/tracks/add"}
			>
				<FontAwesomeIcon icon={faAdd} />
			</Link>
		</div>
	);
}
async function getTracksByParams(prms) {
	return await getAllTracks();
}

export default SearchTrack;
