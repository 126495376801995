import React from 'react';
import MachineLearning from '../Tutor/MACHINE_LEARNING.pdf'
function MachineLearningTutorial() {
    return (
        <iframe
            src={MachineLearning}
            style={{ width: '100%', height: '600px', border: 'none' }}
            title="Data Science Presentation"
        ></iframe>
    );
}

export default MachineLearningTutorial;
