import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const Posts = () => {
  const [questionsData, setQuestionsData] = useState({ questions: [] });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`http://portal.edatoz.com/image/${id ? id : ''}`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setQuestionsData({ questions: data });
        } else {
          console.error('Error fetching imagesaaa:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching imagesbbb:', error);
      }
    };
    fetchImages();
  }, [id]);

  const toggleAnswer = (id) => {
    setQuestionsData((prevData) => ({
      questions: prevData.questions.map((question) =>
        question.id === id ? { ...question, showAnswer: !question.showAnswer } : question
      ),
    }));
  };

  return (
    <Row className='mb-5'>
      <Col md={4}>
        {questionsData.questions.map((question, index) => (
          <div key={index}>
            <Row>
              {question.imageURL && (
                <img src={question.imageURL} alt='Question' style={{ width: '100%', padding: '3px' }} />
              )}
              {!question.showAnswer && (
                <button onClick={() => toggleAnswer(question.id)}>Show Answer</button>
              )}
              {question.showAnswer && (
                <div>
                  {question.AnsURL && (
                    <img src={question.AnsURL} alt='Answer' style={{ width: '100%', padding: '3px' }} />
                  )}
                  <button onClick={() => toggleAnswer(question.id)}>Hide Answer</button>
                </div>
              )}
            </Row>
          </div>
        ))}
      </Col>
      <Col md={8}>
        <iframe title='Embedded Website' src='https://edatoz.com' width='100%' height='500px'></iframe>
      </Col>
    </Row>
  );
};

export default Posts;
