import React from 'react'

function NotFound(props) {
  return (
   <div className="vstack">
    <div className="h1">{props.code||404}</div>
    <div className="h2">{props.text||"URL Not Found"}</div>
   </div>
  )
}

export default NotFound; 