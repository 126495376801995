import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as React, Suspense, useMemo, useState } from "react";
import { Button, Form, FormCheck, FormControl, Stack } from "react-bootstrap";
import { Await, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import { addTrack, getTrack, replaceTrack } from "../../util/trackutil";
import Loading from "../UtilComponents/Loading";
import { getAllCourses } from "../../util/courseutil";
import { removeSpChars } from "../../util/generalutil";

function DisplayTrackManagement(props) {
	let params = useParams();
	const [trackId, setTrackId] = useState("");
	const [courseList, setCourseList] = useState([]);
	const [trackName, setTrackName] = useState("");
	const navigate = useNavigate();
	useMemo(() => {
		if (params.mode == "edit") {
			getTrack(params.track_id).then(function (value) {
				console.log(value.data);
				setTrackId(value.data.track_id);
				setTrackName(value.data.track_name);
				setCourseList(value.data.courses ? value.data.courses.filter(e=>e).map(e => e.course_id) : []);
			});
		}
	}, []);
	function validateForm() {
		return (
			trackName != "" &&
			trackName != null &&
			trackId != null &&
			trackId != "" &&
			courseList.length != 0
		);
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (validateForm()) {
			let trackToBeGiven = {
				track_id: trackId,
				track_name: trackName,
				courses: courseList,
			};
			window.trackToBeGiven = trackToBeGiven;
			if (params.mode == "edit") {
				replaceTrack(trackId, trackToBeGiven).then((result) => {
					console.log(result);
					if (result.data.acknowledged) {
						alert("track updated successfully");
						navigate(-1);
					}
				});
			} else if (params.mode == "add") {
				addTrack(trackToBeGiven).then((result) => {
					if (result.data.acknowledged) {
						alert("track added successfully");
						setTrackId("");
						setTrackName("");
						setCourseList([]);
					} else {
						console.log(result.data);
						alert(result.data);
					}
				});
			}
		} else {
			alert("please fill all the fields");
		}
	}
	return (
		<Form
			variant={"dark"}
			onSubmit={handleSubmit}
			style={{ maxWidth: "600px" }}
			className="h-100 w-100 m-3 card p-3 d-flex justify-content-evenly"
		>
			{params.mode == "add" && (
				<FormControl
					type="text"
					value={trackId}
                    autoFocus
					onKeyDown={(e) => {
						return /^[a-zA-Z0-9_]+$/.test(e.key) ? true : e.preventDefault();
					}}
					onChange={(e) => {
						setTrackId(e.target.value);
					}}
					placeholder="track id"
				/>
			)}
			{params.mode == "edit" && <h1>{trackId}</h1>}
			<FormControl
				type="text"
				value={trackName}
				onChange={(e) => setTrackName(e.target.value)}
				placeholder="track name"
			/>
			[{courseList.map((e) => `"${e}"`).join(",")}]
			{props.courseListOptions &&
				props.courseListOptions.map((course, courseIndexopt) => {
					return (
						<FormCheck
							id={course.course_id}
							checked={courseList.includes(course.course_id)}
							onChange={(e) =>
								e.target.checked
									? courseList.includes(course.course_id) ||
									  setCourseList([...courseList, course.course_id])
									: setCourseList(courseList.filter((e) => e != course.course_id))
							}
							key={courseIndexopt}
							label={course.course_name + ".....@::" + course.course_id}
						/>
					);
				})}
			<Stack direction="horizontal" gap={2} className="justify-content-evenly">
				<Button type="submit" disabled={!validateForm()}>
					<FontAwesomeIcon icon={faAdd} className="ms-2 me-2" />
					<span className="text-capitalize">
						{params.mode}
						&nbsp;Track
					</span>
				</Button>
			</Stack>
		</Form>
	);
}

//below code is to show results
/////////////////////////////////////
function Dummy() {
	const courseListOptions = useAsyncValue().data;
	return <DisplayTrackManagement courseListOptions={courseListOptions} />;
}
function ManageTrack() {
	const params = useParams();
	return (
		<Suspense fallback={<Loading show={"loading tracks"} />}>
			<Await resolve={getAllCourses().catch(console.log)}>
				<Dummy />
			</Await>
		</Suspense>
	);
}

export default ManageTrack;
