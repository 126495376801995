import { axiosWAuth } from "./axiosWAuth";
import { _url, adminOnly, noAuth } from "./middleWareUtil";
import { global_user as user } from "../components/PortalApp";

export async function getAllCourses() {
	if (user) {
		if (user.role == "staff") return await axiosWAuth.get(_url.admin.allCourse);
		else if (user.role == "student") {
			return await axiosWAuth.get(_url.nonAdmin.allCourse + "/" + user._id);
		}
	} else noAuth();
}

export async function getAllCoursesStruct() {
	if (user) {
		if (user.role == "staff") return await axiosWAuth.get(_url.admin.allCourseStruct);
		else if (user.role == "student") {
			return await axiosWAuth.get(_url.nonAdmin.allCourseStruct + "/" + user._id);
		}
	} else noAuth();
}

export async function getCourseStruct(course_id) {
	if (user) {
		if (user.role == "staff") {
			return await axiosWAuth.get(_url.admin.course + "/" + course_id);
		} else if (user.role == "student") {
			return await axiosWAuth.get(_url.nonAdmin.course + "/" + user._id + "/" + course_id);
		}
	} else noAuth();
}
export async function addCourse(course) {
	if (adminOnly(user)) {
		let body = {
			course: course,
		};
		return await axiosWAuth.post(_url.admin.addCourse, body);
	}
}

export async function replaceCourse(course_id, course) {
	if (adminOnly(user)) {
		let body = {
			course_id: course_id,
			course: course,
		};
		return await axiosWAuth.post(_url.admin.replaceCourse, body);
	}
}

export async function deleteCourse(course_id) {
	//todo:make it delete instead of get
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.deleteCourse + "/" + course_id);
	}
}

export async function getSubjectNotes(course_id, chapter_id = null, topic_id = null) {
	let result;
	if (user) {
		if (user.role == "staff")
			result = await axiosWAuth.get(_url.admin.subjectNotes + "/" + course_id);
		else if (user.role == "student") {
			result = await axiosWAuth.get(
				_url.nonAdmin.subjectNotes + "/" + user._id + "/" + course_id
			);
		}
	} else noAuth();

	console.log(result);

	if (chapter_id != null) {
		result.data.chapters = [
			...result.data.chapters.filter((ch) => ch.chapter_id == chapter_id),
		];
		if (topic_id != null) {
			result.data.chapters[0].topics = [
				...result.data.chapters[0].topics.filter((t) => t.topic_id == topic_id),
			];
		}
	}
	return result;
}

export async function addNoteForCourse(course_id, chapter_id, topic_id, notes) {
	if (adminOnly(user)) {
		let body = {
			course_id: course_id,
			chapter_id: chapter_id,
			topic_id: topic_id,
			notes: notes,
		};

		return await axiosWAuth.post(_url.admin.addNotes, body, {
			headers: {
				"Content-Type": "application/json",
			},
		});
	}
}
export async function replaceNotesForCourse(course_id, chapter_id, topic_id, notes) {
	if (adminOnly(user)) {
		let body = {
			course_id: course_id,
			chapter_id: chapter_id,
			topic_id: topic_id,
			notes: notes,
		};

		return await axiosWAuth.post(_url.admin.replaceNotes, body, {
			headers: {
				"Content-Type": "application/json",
			},
		});
	}
}
export async function deleteNotesForCourse(course_id, chapter_id, topic_id) {
	if (adminOnly(user)) {
		let body = {
			course_id: course_id,
			chapter_id: chapter_id,
			topic_id: topic_id,
		};
		return await axiosWAuth.delete(_url.admin.deleteNotes, body, {
			headers: {
				"Content-Type": "application/json",
			},
		});
	}
}
