import React from "react";
import { useState } from "react";
import { getCourseStruct } from "../../util/courseutil";
import { Container, FormSelect, Row, Col} from "react-bootstrap";
import { strNotNull } from "../../util/generalutil";
import SelectExercises from "../Exercises/SelectExercises";
import '../../css/SelectCourseHierarchy.css';

export default function SelectCourseHierarchy(props) {
	let disabled = props.disabled ? props.disabled : false;

	let [courseRef, setCourseRef] = useState(null);
	function loadCourseRef(_course_id) {
		console.log(_course_id);
		getCourseStruct(_course_id)
			.then((result) => {
				console.log(result);
				setCourseRef(result.data);
			})
			.catch((err) => {
				console.log(err);
				console.log("occured while loading courseStruct");
			});
	}
	if (props.invokeLoadCourseRef == true) {
		loadCourseRef(props.courseId);
		props.setInvokeLoadCourseRef(false);
	}
	function handleCourseChange(e) {
		props.setChapterId(null);
		props.setTopicId(null);
		if (e.target.value == "select") {
			props.setCourseId(null);
			setCourseRef(null);
		} else {
			loadCourseRef(e.target.value);
			props.setCourseId(e.target.value);
		}
	}
	function handleChapterChange(e) {
		props.setTopicId(null);
		if (e.target.value == "select") {
			props.setChapterId(null);
		} else props.setChapterId(e.target.value);
	}
	function handleTopicChange(e) {
		if (e.target.value == "select") props.setTopicId(null);
		else props.setTopicId(e.target.value);
	}

	return (
		<>
		<Container fluid>
			<Row>
				<Col sm={12} lg={4}>
				<FormSelect className="mb-0"
				autoFocus={props.autoFocus}
				disabled={disabled}
				onChange={handleCourseChange}
				value={strNotNull(props.courseId)}
			>
				<option value={"select"}>Choose course</option>
				{props.courseList != null &&
					props.courseList.map((course, index) => (
						<option key={index} value={strNotNull(course.course_id)}>
							{course.course_name}
						</option>
					))}
			</FormSelect>
				</Col>
				<Col sm={12} lg={4}>
				{props.courseId == "" ||
				props.courseId == "select" ||
				props.courseId == undefined ||
				props.courseId == null || (
					<FormSelect className="mb-0"
						disabled={disabled}
						onChange={handleChapterChange}
						value={strNotNull(props.chapterId)}
					>
						<option value={"select"}>Choose chapter</option>
						{courseRef != null &&
							courseRef.chapters.map((chapter, index) => (
								<option key={index} value={strNotNull(chapter.chapter_id)}>
									{chapter.chapter_name}
								</option>
							))}
					</FormSelect>
				)}
				</Col>
				<Col sm={12} lg={4}>
				{props.chapterId == "" ||
				props.chapterId == "select" ||
				props.chapterId == null ||
				props.chapterId == undefined || (
					<FormSelect
						disabled={disabled}
						onChange={handleTopicChange}
						value={strNotNull(props.topicId)}
					>
						<option value={"select"}>Choose topic</option>
						{courseRef != null &&
							courseRef.chapters != null &&
							props.chapterId != "" &&
							courseRef.chapters
								.find((chapter) => chapter.chapter_id == props.chapterId)
								.topics.map((topic, index) => (
									<option key={index} value={strNotNull(topic.topic_id)}>
										{topic.topic_name}
									</option>
								))}
					</FormSelect>
				)}
				</Col>
				<Col>
								
				</Col>
			</Row>
		</Container>
			
			
			
		</>
	);
}
