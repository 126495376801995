import React from 'react';
import DS_INTRO from '../Tutor/DS_INTRO.pdf'
function DSTutorial() {
    return (
        <iframe
            src={DS_INTRO}
            style={{ width: '100%', height: '600px', border: 'none' }}
            title="Data Science Presentation"
        ></iframe>
    );
}

export default DSTutorial;
