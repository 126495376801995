import {
	faAngleLeft,
	faAngleRight,
	faFastForward,
	faRotateBack,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { Suspense, useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { Await, useAsyncValue, useNavigate, useParams, useLocation} from "react-router-dom";
import { getFAQ, getFAQById } from "../../util/questionutil";
import PracticeQuestion from "./PracticeQuestion";
import Loading from "../UtilComponents/Loading";
import { updateProgressFaq } from "../../util/progressutil";
import { getComplexities } from "../../util/cplxutil";
import { axiosWAuth } from "../../util/axiosWAuth";
import { global_user } from "../PortalApp";
import '../../css/PracticeFAQ.css';



function HandleAllFAQ(props) {
    const location = useLocation();
	const results = [...props.faq];
	const complexityDict = [...useAsyncValue().data];
	console.log('faqs:');
	console.log(results);
	const questionArray = [...results].flat();
	let navigate = useNavigate();
	const [index, setIndex] = useState(0);
	const [isCorrect, setIsCorrect] = useState(0); //0=correct 1=incorrect
	const [isAnswered, setIsAnswered] = useState(false);

	//question array is the array

	useEffect(() => {
        const currentDate = new Date(); // Get current date and time
        console.log("Current URL path:", location.pathname);
        console.log("Current date and time:", currentDate.toLocaleString()); 
    }, [location]);

	function handleSubmit( e, question, answerid, timeTaken) {
		let isCorrectAnswer = false;
		//todo: use timetaken
		if (answerid == null) {
			alert("please choose an option");
			return null;
		}

		if (questionArray[index].answerIndex === answerid) {
			isCorrectAnswer = true;
			setIsCorrect(true);
		} else {
			isCorrectAnswer = false;
			setIsCorrect(false);
		}
		setIsAnswered(true);
		
		const faqId = question._id;
		const courseId = question.course_id;
		const chapterId = question.chapter_id;
		const topicId = question.topic_id;
		const studentId = global_user._id;
		
		axiosWAuth
			.post("/student/response/faq", {
				student_id: studentId,
				faq_id: faqId,
				course_id: courseId,
				chapter_id: chapterId,
				topic_id: topicId,
				status: (isCorrectAnswer) ? "correct" : "incorrect" ,
				timestamp: Date.now(),
				action: "submitted",
				actionDate: Date.now(),
				actionBy: studentId,
			})
			.then((studentResponse) => {
				console.log("FAQ submission success:", studentResponse.data);
			})
			.catch((studentError) => {
				console.error("FAQ submission error:", studentError);
				alert(studentError.message)
			})
	}


	function tryAgain() {
		setIsAnswered(false);
	}
	function prev() {
		if (index > 0) {
			setIndex(index - 1);
		}
	}

	function next(status) {
		if (status == "completed")
			updateProgressFaq(
				questionArray[index].course_id,
				questionArray[index].chapter_id,
				questionArray[index].topic_id,
				"completed"
			);

		if (index >= questionArray.length - 1) {
			alert("thanks for trying");
			navigate("/dashboard");
		} else {
			setIndex(index + 1);
		}
		setIsAnswered(false);
	}

	function handleAction() {
		if (isCorrect) {
			next("skipped")
		} else {
			tryAgain();
		}
	}

	function skip() {
		next("skipped");
	}

	function whenTimerEnds() {
		skip();
	}
	return (
		<>
			<Stack className="align-items-center justify-content-evenly">

				{questionArray.length == 0 || (
					<PracticeQuestion
						whenTimerEnds={whenTimerEnds}
						isAnswered={isAnswered}
						question={questionArray[index]}
						handleSubmit={handleSubmit}
						skip={skip}
						indexQuesForKey={index}
						complexityDict={complexityDict}
					/>
				)}

				{isAnswered ? (
					<div style={{position:'absolute'}}
						role="alert"
						className={"alert alert-" + (isCorrect ? "success" : "danger")}
					>
						{isCorrect ? ( <span>
          					Your answer is correct <FontAwesomeIcon icon={faCheck} size="2x" style={{ color: 'green', marginLeft: '10px' }} />
        				</span> ) : (
							<span>
							Your answer is wrong <FontAwesomeIcon icon={faTimes} size="2x" style={{ color: 'red', marginLeft: '10px' }} />
							</span>
						)}
							<br/>
						<Button onClick={handleAction} variant="primary" className="ms-2">
							{isCorrect ? "Next" : "Try Again"}
							<FontAwesomeIcon
								className="ms-1 me-1"
								icon={isCorrect ? faAngleRight : faRotateBack}
							/>
						</Button>
					</div>
				) : (
					<>
						{questionArray[index]?.meta?.faq?.stu_status == "completed" &&
							(index == questionArray.length - 1 ? (
								<>Final Question</>
							) : (
								<Button onClick={() => skip()} variant="primary">
									Skip
									<FontAwesomeIcon className="ms-1 me-1" icon={faFastForward} />
								</Button>
							))}
					</>
				)}
				<Button
					className="align-self-start btnposition"
					onClick={prev} variant="danger"
					disabled={index == 0}> <FontAwesomeIcon icon={faAngleLeft} size="1x" /> 
					&nbsp;  Previous
				</Button>
			</Stack>
		</>
	);
}
async function getFAQFromParams(params) {
	// // if (params.faq_id) {
	// // 	return await getFAQ(params.faq_id);
	// // }
	if (params.course_id && params.chapter_id && params.topic_id && params.complexity_id) {
		return await getFAQ(
			params.course_id,
			params.chapter_id,
			params.topic_id,
			params.complexity_id
		);
	}
	if (params.course_id && params.chapter_id && params.topic_id) {
		return await getFAQ(params.course_id, params.chapter_id, params.topic_id);
	}
	if (params.course_id && params.chapter_id) {
		return await getFAQ(params.course_id, params.chapter_id);
	}
	if (params.course_id) {
		return await getFAQ(params.course_id);
	}
}
function GetComplexityAndContinue() {
	let faq = useAsyncValue().data;
	return (
		<Suspense fallback={<Loading show={"loading complexity"} />}>
			<Await resolve={getComplexities()}>
				<HandleAllFAQ faq={faq} />
			</Await>
		</Suspense>
	);
}
function PracticeFAQ() {
	const params = useParams();
	return (
		<Suspense fallback={<Loading show={"loading faq"} />}>
			<Await resolve={getFAQFromParams(params).catch(console.log)}>
				<GetComplexityAndContinue />
			</Await>
		</Suspense>
	);
}
export default PracticeFAQ;