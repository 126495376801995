import { axiosWAuth } from "./axiosWAuth";
import { _url, adminOnly, noAuth } from "./middleWareUtil";
import { global_user as user } from "../components/PortalApp";
async function updateProgressGenre(
	genre_url,
	course_id,
	chapter_id,
	topic_id,
	status,
	timestamp = Date.now()
) {
	if (user)
		if (user.role == "student") {
			let body = {
				course_id: course_id,
				chapter_id: chapter_id,
				topic_id: topic_id,
				timestamp: timestamp,
				status: status,
				studentId: user._id,
			};
			return await axiosWAuth.post(genre_url, body, {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} else throw "student only";
	else noAuth();
}
export async function updateProgressNotes(
	course_id,
	chapter_id,
	topic_id,
	status,
	timestamp = Date.now()
) {
	return await updateProgressGenre(
		_url.nonAdmin.progress.notes,
		course_id,
		chapter_id,
		topic_id,
		status,
		timestamp
	);
}
export async function updateProgressExercises(
	course_id,
	chapter_id,
	topic_id,
	status,
	timestamp = Date.now()
) {
	return await updateProgressGenre(
		_url.nonAdmin.progress.exercises,
		course_id,
		chapter_id,
		topic_id,
		status,
		timestamp
	);
}
export async function updateProgressFaq(
	course_id,
	chapter_id,
	topic_id,
	status,
	timestamp = Date.now()
) {
	return await updateProgressGenre(
		_url.nonAdmin.progress.faq,
		course_id,
		chapter_id,
		topic_id,
		status,
		timestamp
	);
}
export async function updateBatchProgress(batch) {
	return await axiosWAuth.post(_url.admin.progress, { batch_id: batch._id, courses: batch.courses });
}
