import axios from 'axios';
import { useState, useEffect } from 'react';
import config from "../json/config.json";
import { Table } from 'react-bootstrap';

function ErrorLogs() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(config.backEnd.hostURL + '/errors');
        if (response.data && response.data.length > 0) {
          // Sort data by timestamp in descending order (latest to earliest)
          const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(); // Fetch data initially when the component mounts

    const intervalId = setInterval(() => {
      fetchData(); // Fetch data at regular intervals
    }, 600000); // Adjust the interval time as needed (e.g., 60 seconds)

    // Clean up interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures useEffect runs only once
  return (
    <Table>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Message</th>
          <th>Stacktrace</th>
          {/* Add more headers as needed */}
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item._id}>
            <td>{item.timestamp}</td>
            <td>{item.message}</td>
            <td>{item.stackTrace}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
export default ErrorLogs;