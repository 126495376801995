import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Button, FormControl } from "react-bootstrap";
function TimeStr({ time, id, className }) {
	const s = Math.floor(time % 60);
	const m = Math.floor((time / 60) % 60);
	const h = Math.floor((time / 60 / 60) % 24);

	return (
		<div id={id} className={className}>
			{[h != 0 && (h > 9 ? h : "0" + h), m != 0 && (m > 9 ? m : "0" + m), s > 9 ? s : "0" + s]
				.filter((e) => e)
				.join(":")}
		</div>
	);
}
function Countdown({ time, whenDone, pause, onProgress,variant }) {
	let refInstance = useRef(null);
	const [all, setAll] = useState(time);
	const setAllTime = () => {
		if (!pause) {
			if (all > 0) {
				onProgress(all - 1);
				setAll(all - 1);
			} else whenDone();
		}
	};
	let reset = () => {
		if (refInstance.current) clearInterval(refInstance.current);
		let id = setInterval(() => {
			setAllTime();
		}, 1000);
		refInstance.current = id;
	};
	useEffect(() => {
		reset();
		return () => {
			if (refInstance.current) clearInterval(refInstance.current);
		};
	});

	return ( 
		<>
			<h1
				className={
					"bg-"+variant+
					" p-3 rounded-pill pe-none user-select-none"
				}
				id="countdown"
			>
				<TimeStr time={all} id="countdown-number" className={"text-dark"} />
			</h1>
		</>
	);
}
function TimePicker({ time, onChange, min, max }) {
	function setTime(e) {
		onChange(Math.max(Math.min(e, max), min));
	}

	function setMin(e) {
		setTime(e * 60 + (time % 60));
	}
	function setSecond(e) {
		let s = e >= 0 ? e : 60 + e;

		//here e is negative like -1 then 60 + e means like 60 + (-1) giving 60 -1 = 59
		setTime(Math.floor(time / 60) * 60 + (s % 60));
	}
	function minute() {
		return Math.floor(time / 60);
	}
	function second() {
		return time % 60;
	}
	return (
		<div className="hstack align-items-center justify-content-center gap-2">
			<div className="vstack  align-items-center justify-content-center">
				<Button tabIndex={-1}
					onClick={() => setMin(minute() + 1)}
					className="bg-transparent outline-0 border-0"
				>
					<FontAwesomeIcon icon={faAdd} />
				</Button>
				<FormControl
					type="number"
					value={minute()}
					min={Math.floor(min / 60)}
					max={Math.floor(max / 60)}
					step={1}
					onChange={(e) => setMin(e.target.value)}
                    onKeyDown={setMinutebyKeys}
				/>
				<Button tabIndex={-1}
					onClick={() => setMin(minute() - 1)}
					className="bg-transparent outline-0 border-0"
				>
					<FontAwesomeIcon icon={faMinus} />
				</Button>
			</div>
                <span>m</span>
			<div className="vstack  align-items-center justify-content-center">
				<Button tabIndex={-1}
					onClick={() => setSecond(second() + 1)}
					className="bg-transparent outline-0 border-0"
				>
					<FontAwesomeIcon icon={faAdd} />
				</Button>
				<FormControl
					type="number"
					value={second()}
					min={0}
					max={59}
					step={1}
					onKeyDown={setSecondbyKeys}
					onChange={(e) => setSecond(e.target.value)}
				/>
				<Button tabIndex={-1}
					onClick={() => setSecond(second() - 1)}
					className="bg-transparent outline-0 border-0"
				>
					<FontAwesomeIcon icon={faMinus} />
				</Button>
			</div>
                <span>s</span>
		</div>
	);

    function setMinutebyKeys(e) {
       
            switch (e.key) {

                case "ArrowUp":
                case "ArrowRight":
                case "+":
                    setMin(minute() + 1);
                    e.preventDefault();
                    break;
                case "ArrowDown":
                case "ArrowLeft":
                case "-":
                    setMin(minute() - 1);
                    e.preventDefault();
                    break;
            }
        
    }
    function setSecondbyKeys(e) {
       
            switch (e.key) {

                case "ArrowUp":
                case "ArrowRight":
                case "+":
                    setSecond(second() + 1);
                    e.preventDefault();
                    break;
                case "ArrowDown":
                case "ArrowLeft":
                case "-":
                    setSecond(second() - 1);
                    e.preventDefault();
                    break;
            }
        
    }
}
export { TimeStr, TimePicker };
export default Countdown;
