import { useContext, useState } from "react";
import { Button, Nav, Navbar, Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun, faUser} from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/logo.png";
import { UserContext } from "../../context/userContext";
import "../../css/Navbar.css";

function NavBar(props) {
    const [theme, setTheme] = useState("light")
    let user = useContext(UserContext);
    return (

        <Navbar expand="sm" className="navBar  navbar-dark d-flex">
            <Container fluid>
                <Navbar.Brand href="#Edatoz">
                    <Image src={logo} style={{ height: 50, width: 50, borderRadius: '25px', marginleft: '40px' }} />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav" className="w-50 justify-content-end p-2">
                    <Nav className="align-items-center align-self-end" >
                        <Button className="bg-transparent p-0 border-0 mx-2 my-2"
                            onClick={() => { 
                                let theme1 = document.getElementById("myHtml").getAttribute("data-theme")
                                theme1 = (theme === "dark") ? "light": "dark";
                                document.getElementById("myHtml").setAttribute("data-theme", theme1)
                                document.getElementById("myHtml").setAttribute("data-bs-theme", theme1)
                                setTheme(theme1)
                            }}>

                            {theme == "dark" && <FontAwesomeIcon icon={faSun} />}
                            {theme == "light" && <FontAwesomeIcon icon={faMoon} />}
                            {theme == null && <FontAwesomeIcon icon={faMoon} />}
                        </Button>
                        <Link target="_new" className=" mx-4 my-2" to="http://edatoz.com/">Edatoz.com</Link>
                        <Link className=" mx-4 my-2" to="/home">Home</Link>
                        <Link className=" mx-4 my-2" to="/contactus">Contact Us</Link>
                        {
                            (user == null) ?
                                <Link className=" mx-4 my-2" to="/login">Login</Link>
                                :
                                <Link className=" mx-4 my-2" to="/logout">Logout</Link>
                        }
                        {/* {
                            (user == null) ?
                            <></>
                            :
                            <Link className=" mx-4 my-2" to="/profile"> <FontAwesomeIcon icon={faUser} className="mr-1" /></Link>
                        } */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default NavBar;