import { faArrowDown, faArrowUp, faEdit, faRedo, faSearch, faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Stack, Table } from 'react-bootstrap';
import { Await, Link, useAsyncValue, useNavigate, useParams } from 'react-router-dom';
import { getAllCourses } from '../../util/courseutil';
import { getComplexities } from '../../util/cplxutil';
import { makePath, splitN } from '../../util/generalutil';
import { deleteExercise, getExercises } from '../../util/questionutil';
import Loading from '../UtilComponents/Loading';
import SelectComplexity from '../UtilComponents/SelectComplexity';
import SelectCourseHierarchy from '../UtilComponents/SelectCourseHierarchy';
import { UserContext } from '../../context/userContext';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';

export default function SearchExercise(props) {
    return (
        <Suspense fallback={<Loading show={"loading Exercises"} />}>
            <Await resolve={getAllCourses().catch(console.log)} >
                <GetComplexitiesAndContinue {...props} />
            </Await>
        </Suspense>
    )
}

function GetComplexitiesAndContinue(props) {
    const courseList = useAsyncValue().data;
    return (
        <Suspense fallback={<Loading show={"loading complexities"} />}>
            <Await resolve={getComplexities()}>
                <SearchForm courseList={courseList} {...props} />
            </Await>
        </Suspense>

    )
}
function SearchForm(props) {
    const complexityDict = useAsyncValue().data;
    const navigate = useNavigate();
    let [courseId, setCourseId] = useState(null)
    let [chapterId, setChapterId] = useState(null)
    let [topicId, setTopicId] = useState(null)
    let [complexity, setComplexity] = useState(null);
    function validateForm() {

        return (courseId !== "") &&
            (courseId !== null);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (validateForm()) {
            console.log(makePath(["/ex", "search", courseId, chapterId, topicId, complexity])
            );
            navigate(
                makePath(["/ex", "search", courseId, chapterId, topicId, complexity])
            );
        }
    }
    useEffect(() => {
        if (topicId == null || topicId == "")
            setComplexity("")
    }, [topicId])
    return (
        <>
            <Form onSubmit={handleSubmit} className='card p-3 gap-3'>


                <SelectCourseHierarchy
                    courseList={props.courseList}
                    courseId={courseId} setCourseId={setCourseId}
                    chapterId={chapterId} setChapterId={setChapterId}
                    topicId={topicId} setTopicId={setTopicId}
                    autoFocus
                />
                <SelectComplexity complexityDict={complexityDict}
                    disabled={topicId == "" || topicId == null}
                    complexity={complexity} setComplexity={setComplexity} />
                <Button type="submit" variant="primary" disabled={!validateForm()}>
                    <FontAwesomeIcon className='me-2' icon={faSearch} />
                    Search
                </Button>
            </Form>

        </>
    )
}
///////////////////////////////////////
//above code is to just search





//below code is to show results
/////////////////////////////////////
function deleteThisEx(id, refOfExercise, reload) {
    deleteExercise(id).then(
        results => {
            console.log(results);
            if (results.data != null && results.status == 200) {
                alert("deleted");
                reload();
                //reload contents of table

            }
        }
    )
}

function ShowOneExercise(props) {

    let params = useParams();
    let exRef = useRef();
    let exComplexity = {
        ...props.complexityDict.find(item =>
            (item.complexity_id == props.exercise.complexity_id)
        )
    }
    console.log(exComplexity);




    return (
        <tr style={{ background: props.bg }} className='tdnobg' ref={exRef}>
            <td className='small text-muted pe-none user-select-none'  >
                <Stack style={{ fontSize: '.7em' }}>
                    {
                        splitN(props.exercise._id, 6)
                            .map((item, index) => (
                                <span key={index}>{item}</span>
                            )
                            )
                    } 
                </Stack>
            </td>
 
            <td>{props.exercise.description}</td>
            <td>{props.exercise.hint}</td>
            {params.chapter_id == null && <td>{props.exercise.chapter_id}</td>}
            {params.topic_id == null && <td>{props.exercise.topic_id}</td>}
            <td>{exComplexity.complexity_name}</td>
            {
                props.user.role == "staff" &&
                <>
                    <td>
                        <Link to={'/ex/edit/' + props.exercise._id} relative='route' >
                            <FontAwesomeIcon icon={faEdit} />
                        </Link>
                    </td>
                    <td>
                        <Button variant='danger' onClick={() => deleteThisEx(props.exercise._id, exRef, props.reload)} >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </td>
                </>
            }
            {
                props.user.role == "student" &&
                <td>
                    <Link to={'/ex/do/id/' + props.exercise._id}>
                        <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </Link>
                </td>
            }
        </tr>
    )
}
function ManageAllExercises(props) {

    let user = useContext(UserContext);
    let params = useParams();
    let navigate = useNavigate();
    const [fullExArray, setFullExArray] = useState(props.exercises);

    function reload() {

        getExercisesByParams(params).then(
            results => {
                setFullExArray(results.data);
                setComplexity("");

                if (order == 0) {
                    setArrayEx([...results.data])
                } else
                    setArrayEx(
                        [...results.data]
                            .sort(
                                (a, b) =>
                                (order *
                                    (a.complexity_id - b.complexity_id)
                                )
                            )
                    )

            }
        ).catch(console.log)
    };

    const complexityDict = [...useAsyncValue().data];
    const [complexity, setComplexity] = useState("");
    let [arrayEx, setArrayEx] = useState(fullExArray);
    const [order, setOrder] = useState(0);


    useEffect(() => {
        setArrayEx(
            [...arrayEx]
                .sort(
                    (a, b) =>
                    (order *
                        (a.complexity_id - b.complexity_id)
                    )
                )
        )
    }, [order]);

    function filterArrayEx(complexity_id) {
        if (complexity_id == "")
            setArrayEx(
                (order == 0) ?
                    [...fullExArray] :
                    [...fullExArray].sort(
                        (a, b) =>
                        (order *
                            (a.complexity_id - b.complexity_id)
                        )
                    )
            );
        else
            setArrayEx(
                (order == 0) ?
                    [...fullExArray].filter(exercise =>
                        (exercise.complexity_id == complexity_id)
                    )
                    :
                    [...fullExArray].filter(exercise =>
                        (exercise.complexity_id == complexity_id)
                    ).sort((a, b) => {
                        return order * (a.complexity_id - b.complexity_id)
                    }
                    )
            )

    }
    useEffect(() => {
        filterArrayEx(complexity)
    }, [complexity])



    if (fullExArray == null)
        return (
            <>
                Exercises on this topic
                are to be added soon
                <Loading show="..." /></>
        )

    return (
        <div className='exerciseContainer w-100 h-100'>
            <Stack direction='horizontal'
                gap={2}
                className='justify-content-evenly'>
                <Button onClick={() => reload()} >
                    <FontAwesomeIcon icon={faRedo} />
                </Button>

                <div>
                    complexity

                    <SelectComplexity
                        complexityDict={complexityDict}
                        complexity={complexity} setComplexity={setComplexity} />

                </div>
                <div className='vr' />
                <div>

                    <Form.Check type='radio' label='Ascending' name='order'
                        onClick={() => setOrder(1)} />
                    <Form.Check type='radio' label='Descending' name='order'
                        onClick={() => setOrder(-1)} />
                </div>
                <Button onClick={() => { navigate('/ex/search') }}>
                    <FontAwesomeIcon icon={faSearch} />
                </Button>
            </Stack>
            <div>{arrayEx.length} records found</div>

            <Table>
                <thead>
                    <tr>
                        <th>_id</th>
                        <th>description</th>
                        <th>hint</th>
                        {params.chapter_id == null && <th>chapter_id</th>}
                        {params.topic_id == null && <th>topic_id</th>}
                        <th className='d-flex align-items-center'>complexity_id
                            {order == 1 && <FontAwesomeIcon icon={faArrowDown} onClick={() => setOrder(-1)} />}
                            {order == -1 && <FontAwesomeIcon icon={faArrowUp} onClick={() => setOrder(1)} />}
                        </th>
                        {
                            user.role == "staff" &&
                            <>
                                <th>edit</th>
                                <th>delete</th>
                            </>
                        }
                        {
                            user.role == "student" &&
                            <>
                                <th>do</th>
                            </>
                        }
                    </tr>
                </thead>
                <tbody>

                    {
                        arrayEx.map((exercise, exIndex) =>
                        (

                            <ShowOneExercise
                                bg={['#a0d2eb', '#e5eaf5'][exIndex % 2]}
                                key={exIndex}
                                exercise={exercise}
                                user={user}
                                complexityDict={complexityDict}
                                reload={reload} />

                        )
                        )
                    }
                </tbody>
            </Table>
        </div >
    )
}
function GetComplexityAndContinue() {
    let exercises = useAsyncValue().data;
    return (
        <Suspense fallback={<Loading show={"loading complexity"} />}>
            <Await resolve={getComplexities()} >
                <ManageAllExercises exercises={exercises} />
            </Await>
        </Suspense>
    )
}
export function SearchResultsExercises() {
    const params = useParams();

    return (<>
        <Suspense fallback={<Loading show={"loading exercise"} />}>
            <Await resolve={
                getExercisesByParams(params).catch(console.log)
            }>
                <GetComplexityAndContinue />
            </Await>
        </Suspense>

    </>
    )
}
function getExercisesByParams(params) {
    if (params.course_id) {
        if (params.chapter_id) {
            if (params.topic_id) {
                if (params.complexity_id) {
                    return getExercises(params.course_id, params.chapter_id, params.topic_id, params.complexity_id).catch(console.log)
                }
                return getExercises(params.course_id, params.chapter_id, params.topic_id, params.complexity_id).catch(console.log)
            }
            return getExercises(params.course_id, params.chapter_id, params.complexity_id).catch(console.log)
        }
        return getExercises(params.course_id);
    }
}

