import React from 'react';
import Python from '../Tutor/Python_Programming.pdf'
function PythonTutorial() {
    return (
        <iframe
            src={Python}
            style={{ width: '100%', height: '600px', border: 'none' }}
            title="Python Presentation"
        ></iframe>
    );
}

export default PythonTutorial;
