import React from 'react';
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const FileDownload = ({ studentId, exerciseId }) => {
  const handleDownload = () => {
    //const downloadUrl = `http://localhost:5000/download/${studentId}/${exerciseId}`;
    const downloadUrl = `https://portal.edatoz.com:8080/download/${studentId}/${exerciseId}`;
    window.open(downloadUrl, '_blank');
  };

  return (
    <div>
      <button onClick={handleDownload}>
        <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon></button>
    </div>
  );
};

export default FileDownload;