import React, { Suspense, useMemo, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Await, useAsyncValue, useNavigate, useParams } from 'react-router-dom';
import { getAllCourses } from '../../util/courseutil';
import { getComplexities } from '../../util/cplxutil';
import { addExercise, getExerciseById, replaceExercise } from '../../util/questionutil';
import Loading from '../UtilComponents/Loading';
import SelectComplexity from '../UtilComponents/SelectComplexity';
import SelectCourseHierarchy from '../UtilComponents/SelectCourseHierarchy';

import { faAdd, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const modeIcon = {
    "edit": faEdit,
    "add": faAdd
};

export default function AddNewExercise(props) {
    return (
        <Suspense fallback={<Loading show={"loading courses"} />}>
            <Await resolve={getAllCourses().catch(console.log)}>
                <GetCourseAndContinue />
            </Await>
        </Suspense>
    );
}

function GetCourseAndContinue(props) {
    const courseList = useAsyncValue().data;
    return (
        <Suspense fallback={<Loading show={"loading complexities"} />}>
            <Await resolve={getComplexities()}>
                <GetComplexityAndContinue courseList={courseList} {...props} />
            </Await>
        </Suspense>
    );
}

function GetComplexityAndContinue(props) {
    let complexityDict = useAsyncValue().data;
    let navigate = useNavigate();
    let [courseId, setCourseId] = useState(null);
    let [chapterId, setChapterId] = useState(null);
    let [topicId, setTopicId] = useState(null);
    let [complexity, setComplexity] = useState(null);

    let [invokeLoadCourseRef, setInvokeLoadCourseRef] = useState(false);
    let [text, setText] = useState("");
    let [hint, setHint] = useState("");

    let params = useParams();
    useMemo(() => {
        if (params) {
            console.log(params);
            if (params.mode === "edit") {
                if (params.exercise_id)
                    getExerciseById(params.exercise_id).then(
                        function (value) {
                            setCourseId(value.data.course_id);
                            setInvokeLoadCourseRef(true);
                            setChapterId(value.data.chapter_id);
                            setTopicId(value.data.topic_id);
                            setComplexity(value.data.complexity_id);
                            setText(value.data.description);
                            setHint(value.data.hint);
                        }
                    ).catch(console.log);
            }
        }
    }, [params]);

    function validateForm() {
        return (
            (courseId !== "") &&
            (chapterId !== "") &&
            (topicId !== "") &&
            (courseId !== null) &&
            (chapterId !== null) &&
            (topicId !== null) &&
            (complexity !== null) &&
            (complexity !== "")
        );
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (validateForm()) {
            if (params.mode === "edit") {
                replaceExercise(params.exercise_id, courseId, chapterId, topicId, {
                    description: text,
                    hint: hint,
                    complexity_id: complexity
                }).then(result => {
                    console.log(result);
                    if (result.data != null) {
                        alert("Exercise updated successfully");
                        navigate(-1);
                    }
                });
            } else if (params.mode === "add") {
                addExercise(courseId, chapterId, topicId, {
                    description: text,
                    hint: hint,
                    complexity_id: complexity
                }).then(result => {
                    console.log(result);
                    if (result.data.acknowledged) {
                        alert("Exercise added successfully");
                        setText("");
                        setHint("");
                    }
                });
            }
        } else {
            alert("Please fill all the mandatory fields");
        }
    }

    return (
        <Form onSubmit={handleSubmit} className='w-100 card p-3 gap-3 maxw600'>
            <SelectCourseHierarchy
                courseList={props.courseList}
                invokeLoadCourseRef={invokeLoadCourseRef}
                setInvokeLoadCourseRef={setInvokeLoadCourseRef}
                courseId={courseId} setCourseId={setCourseId}
                chapterId={chapterId} setChapterId={setChapterId}
                topicId={topicId} setTopicId={setTopicId} autoFocus
            />

            <SelectComplexity
                complexityDict={complexityDict}
                complexity={complexity} setComplexity={setComplexity}
            />

            <FormControl as={"textarea"}
                value={text}
                placeholder='Enter Exercise'
                className='h-50'
                onInput={e => setText(e.target.value)}
            />
            <FormControl type='text'
                value={hint}
                placeholder='Enter Hint (Optional)'
                onInput={e => setHint(e.target.value)}
            />

            <Button type='submit' disabled={!validateForm()}>
                <FontAwesomeIcon icon={modeIcon[params.mode]} className='ms-2 me-2' />
                <span className='text-capitalize'>
                    {params.mode}
                    &nbsp;Exercise
                </span>
            </Button>
        </Form>
    );
}
