import { faReact } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../../css/Loading.css";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "react-bootstrap";

function Loading(props) {
	let text;
	if (props.show) text = props.show;
	else text = "loading";
	return (
		<div className="loading">
			<Stack direction="horizontal" className=" user-select-none pe-none" gap={2}>
				{text.split("").map((letter, index) => {
					return (
						<span
							style={{ "--i": index / text.length }}
							className="particle"
							key={index}
						>
							{letter}
						</span>
					);
				})}
			</Stack>
		</div>
	);
}

export default Loading;
