import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Await, Link, useAsyncValue, useNavigate, useParams } from 'react-router-dom';
import { getAllCourses, getSubjectNotes } from '../../util/courseutil';
import Loading from '../UtilComponents/Loading';
import SelectCourseHierarchy from '../UtilComponents/SelectCourseHierarchy';

export default function SearchNotes(props) {
    return (
        <Suspense fallback={<Loading show={"loading Exercises"} />}>
            <Await resolve={getAllCourses().catch(console.log)} >
                <SearchForm {...props} />
            </Await>
        </Suspense>
    )
}

function SearchForm(props) {
    const navigate = useNavigate();
    let courseList = [...useAsyncValue().data]
    let [courseId, setCourseId] = useState(null)
    let [chapterId, setChapterId] = useState(null)
    let [topicId, setTopicId] = useState(null)
    function validateForm() {

        return (courseId !== "") &&
            (courseId !== null);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (validateForm()) {
            console.log(courseId, chapterId, topicId);
            let searchURL = "/notes/search/" + courseId
            if (chapterId != null && chapterId != "")
                searchURL += "/" + chapterId
            if (topicId != null && topicId != "")
                searchURL += "/" + topicId;
            navigate(searchURL);
        }
    }
    return (
        <>
            <Form onSubmit={handleSubmit} className='card p-3 gap-3'>


                <SelectCourseHierarchy
                    courseList={courseList}
                    courseId={courseId} setCourseId={setCourseId}
                    chapterId={chapterId} setChapterId={setChapterId}
                    topicId={topicId} setTopicId={setTopicId}
                    autoFocus
                />
                <Button type="submit" variant="primary" disabled={!validateForm()}>
                    <FontAwesomeIcon className='me-2' icon={faSearch} />
                    Search
                </Button>
            </Form>

        </>
    )
}
///////////////////////////////////////
//above code is to just search





//below code is to show results
/////////////////////////////////////

function DisplayNotesActions() {

    let notes = useAsyncValue().data;

    return (
        <div className="subjectCont w-100 h-100">
            <div className="subject m-3 p-3 card">
                <h1 className="mb-5 fw-bolder">{notes.course_name}</h1>
                {
                    notes.chapters.map(
                        (chapter, chapterIndex) =>
                            <div className="chapter" key={chapterIndex}>
                                <h3 className="mb-3 mt-3 fw-bold ">
                                    {chapter.chapter_name}
                                </h3>
                                {
                                    chapter.topics.map(
                                        (topic, topicIndex) =>
                                        (
                                            <div className="topic" key={topicIndex}>
                                                <h4 className="ps-3">{topic.topic_name}</h4>
                                                <ul className="ps-5">
                                                    {
                                                        topic.content.map(
                                                            (point, pointIndex) =>
                                                            (
                                                                // <li key={pointIndex} className="mb-1">{point}</li>
                                                                <li key={pointIndex} className="mb-1">
                                                                     <div dangerouslySetInnerHTML={{ __html: point }} />
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                </ul>
                                                <Link to={'/notes/edit/'+notes.course_id+'/'+chapter.chapter_id+'/'+topic.topic_id} relative='route'>
                                                    <FontAwesomeIcon icon={faEdit} className='me-2' />
                                                    Edit
                                                </Link>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                    )

                }


            </div>
        </div>
    )
}
async function getNotesByParams(prms) {
    if (prms.course_id && prms.chapter_id && prms.topic_id)
        return await getSubjectNotes(prms.course_id, prms.chapter_id, prms.topic_id).catch(console.log);
    else if (prms.course_id && prms.chapter_id)
        return await getSubjectNotes(prms.course_id, prms.chapter_id).catch(console.log);
    else if (prms.course_id)
        return await getSubjectNotes(prms.course_id).catch(console.log);
}

export function SearchResultNotes() {

    const params = useParams()


    return (<>
        <Suspense fallback={<Loading show={"loading notes"} />}>
            <Await resolve={getNotesByParams(params)}>
                <DisplayNotesActions />
            </Await>
        </Suspense>
    </>);



}
