import axios from 'axios';
import { useState, useEffect } from 'react';
import config from "../json/config.json";
import { Table, Alert } from 'react-bootstrap';

function UserInfoDashboard() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(config.backEnd.hostURL + '/userinfodashboard');
        if (response.data && response.data.length > 0) {
          // Sort data by timestamp in descending order (latest to earliest)
          const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setData(sortedData);
        }
      } catch (error) {
        console.error(error);
        setError('Failed to fetch data.');
      }
    };
    fetchData(); // Fetch data initially when the component mounts
  }, []); // Empty dependency array ensures useEffect runs only once
  
  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Role</th>
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item._id}>
              <td>{item.username}</td>
              <td>{item.email}</td>
              <td>{item.mobileNo}</td>
              <td>{item.role}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default UserInfoDashboard;
