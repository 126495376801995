import { default as React, Suspense, useEffect, useMemo, useState } from "react";
import { Await, useAsyncValue, useNavigate, useParams } from "react-router-dom";
import { Button, Form, FormControl } from "react-bootstrap";
import { faAdd, faEdit, faInfoCircle, faTrash, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addCourse, getCourseStruct, replaceCourse } from "../../util/courseutil";
import { checkDuplicates, strNotNull } from "../../util/generalutil";
import Loading from "../UtilComponents/Loading";
import { afterSplice } from "../../util/generalutil";

function DisplayTopicManagement(props) {
	let topic = props.topic;
	function setTopicId(e) {
		props.setTopic({ ...topic, topic_id: e.target.value });
	}
	function setTopicOrder(e) {
		props.setTopic({ ...topic, topic_order: e.target.value });
	}
	function setTopicName(e) {
		props.setTopic({ ...topic, topic_name: e.target.value });
	}
	return (
		<div className="topic my-2 gap-1 vstack ">
			<div className="hstack gap-3">
				<div className="d-none">
				{ <FormControl
					value={topic.topic_id}
					onChange={setTopicId}
					placeholder="id"
					className="w-25"
				/> }
				</div>

				<FormControl
					type="number"
					value={topic.topic_order}
					onChange={setTopicOrder}
					placeholder="order"
					className="w-25"
				/>
				<FormControl
					type="text"
					value={topic.topic_name}
					onChange={setTopicName}
					placeholder="topic name"
				/>
				<Button variant="danger" onClick={props.deleteTopic}>
					<FontAwesomeIcon className="" icon={faTrash} onClick={props.addTopic} />
				</Button>
			</div>
		</div>
	);
}
function DisplayChapManagement(props) {
	const chapter = props.chapter;
	function setChapterId(e) {
		props.setChapter({ ...chapter, chapter_id: e.target.value });
	}
	function setChapterName(e) {
		props.setChapter({ ...chapter, chapter_name: e.target.value });
	}

	function setChapterOrder(e) {
		props.setChapter({ ...chapter, chapter_order: e.target.value });
	}
	function setTopic(e, topicIndex) {
		let tempChap = { ...chapter };
		tempChap.topics[topicIndex] = e;
		props.setChapter(tempChap);
	}
	function addTopic() {
		let tempChap = { ...chapter };
		tempChap.topics.push({ topic_id: tempChap.topics.length + 1, topic_name: "" });
		props.setChapter(tempChap);
	}
	function deleteTopic(topicIndex) {
		let tempChap = { ...chapter };
		tempChap.topics.splice(topicIndex, 1);
		props.setChapter(tempChap);
	}
	return (
		<div className="chapter vstack ms-4 gap-1">
			{ <div className="hstack gap-3 justify-content-between d-none">
				<FormControl
					type="text"
					value={props.chapter.chapter_id}
					onKeyDown={(e) => {
						return /^[a-zA-Z0-9_]+$/.test(e.key) ? true : e.preventDefault();
					}}
					onChange={setChapterId}
					placeholder="chapter id"
				/>
				<Button variant="danger" onClick={props.deleteChapter}>
					<FontAwesomeIcon className="" icon={faTrash} />
				</Button>
			</div> }
			<FormControl
				type="text"
				value={props.chapter.chapter_name}
				onChange={setChapterName}
				placeholder="chapter name"
			/>
			<FormControl
				type="number"
				value={props.chapter.chapter_order}
				onChange={setChapterOrder}
				placeholder="chapter order"
			/>
			<div className="topicCont vstack gap-1 card p-2 my-1 ms-4 me-3">
				{props.chapter.topics.map((topic, topicIndex) => (
					<DisplayTopicManagement
						key={topicIndex}
						topic={topic}
						setTopic={(e) => setTopic(e, topicIndex)}
						deleteTopic={() => deleteTopic(topicIndex)}
					/>
				))}
				<Button variant="primary" onClick={addTopic} className="align-self-end">
					<FontAwesomeIcon className="" icon={faAdd} />
				</Button>
			</div>
		</div>
	);
}

function DisplayCourseManagement(props) {
	let params = useParams();
	const [courseId, setCourseId] = useState(null);
	const [courseName, setCourseName] = useState("");
	const [chapters, setChapters] = useState([]);
	const courseStructImported = useAsyncValue();
	const [formValid, setFormValid] = useState(false);
	const [warn, setWarn] = useState(false);
	const navigate = useNavigate();

	useMemo(() => {
		if (params.mode == "edit") {
			console.log(courseStructImported);
			setCourseId(courseStructImported.data.course_id);
			setCourseName(courseStructImported.data.course_name);
			setChapters(
				courseStructImported.data.chapters ? courseStructImported.data.chapters : []
			);
		}
	}, []);

	useEffect(() => {
		setFormValid(validateForm(true));
	}, [courseId, courseName, chapters]);

	function validateForm(warnIt = false) {
		function warnThem(e) {
			if (warnIt) {
				setWarn(e);
			}
		}
		if (courseName != "" && courseName != null) {
		} else {
			warnThem("fill out course name");
			return false;
		}

		if (courseId != null && courseId != "") {
		} else {
			warnThem("fill out course id");
			return false;
		}

		if (chapters.length != 0) {
		} else {
			warnThem("minimum one chapter");
			return false;
		}

		if (checkDuplicates(chapters.map((chap) => chap.chapter_order))) {
		} else {
			setWarn("there is a duplicate in course_id");
			return false;
		}

		if (
			chapters.every((chap, chapterIndex) => {
				if (
					chap.chapter_order != null &&
					chap.chapter_order != "" &&
					chap.chapter_name != null &&
					chap.chapter_name != ""
				) {
					return true; //goes to if and satsifies it
				} else {
					warnThem(
						strNotNull(chap.chapter_order) == ""
							? strNotNull(chap.chapter_name) == ""
								? `fill out order and name of chapter at ${chapterIndex + 1}`
								: `fill out order of chapter:${chap.chapter_name} at ${
										chapterIndex + 1
								  }`
							: strNotNull(chap.chapter_name) == ""
							? `fill out name of chapter@${chap.chapter_order} at ${chapterIndex + 1}`
							: ""
					);
					return false;
				}
			})
		) {
		} else {
			return false;
		}

		//check topicId duplicates
		if (
			///forEach chapter
			chapters.every((chap, chapterIndex) => {
				let topicIdArr = chap.topics.map((topic) => topic.topic_order);
				if (checkDuplicates(topicIdArr)) {
					return true; //goes to if and satsifies it
				} else {
					warnThem("there is a duplicate in topic_order in " + chap.chapter_order);
					return false;
				}
			})
		) {
		} else {
			return false;
		}

		//checks topic_id and topic_name
		if (
			chapters.every((chap, chapIndex) => {
				return chap.topics.every((topic, topicIndex) => {
					if (
						topic.topic_order != null &&
						topic.topic_order != "" &&
						topic.topic_name != null &&
						topic.topic_name != ""
					) {
						return true;
					} else {
						warnThem(
							strNotNull(topic.topic_order) == ""
								? strNotNull(topic.topic_name) == ""
									? `fill out order and name of topic at ${topicIndex + 1}`
									: `fill out order of topic:${topic.topic_name} at ${
											topicIndex + 1
									  }`
								: strNotNull(topic.topic_name) == ""
								? `fill out name of topic@${topic.topic_order} at ${topicIndex + 1}`
								: ""
						);
						return false;
					}
				});
			})
		) {
		} else {
			return false;
		}
		//checks length of topics
		if (
			chapters.every((chap, chapIndex) => {
				if (chap.topics.length > 0) {
					return true;
				} else {
					warnThem("minimum one topic for " + chap.chapter_name + "@" + chap.chapter_order);
					return false;
				}
			})
		) {
		} else {
			return false;
		}
		setWarn("");
		return true;
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (validateForm()) {
			let courseToBeGiven = {
				course_id: courseId,
				course_name: courseName,
				chapters: chapters,
			};
			window.courseToBeGiven = courseToBeGiven;
			if (params.mode == "edit") {
				replaceCourse(courseId, courseToBeGiven)
					.then((result) => {
						console.log(result);
						if (result.data.acknowledged) {
							alert("course updated successfully");
							navigate(-1);
						}
					})
					.catch(console.log);
			} else if (params.mode == "add") {
				addCourse(courseToBeGiven)
					.then((result) => {
						if (result.data.acknowledged) {
							alert("course added successfully");
							setCourseId("");
							setCourseName("");
							setChapters([]);
						} else {
							console.log(result.data);
							alert(result.data);
						}
					})
					.catch(console.log);
			}
		} else {
			alert("please fill all the fields");
		}
	}
	
	return (
		<Form
			variant={"dark"}
			onSubmit={handleSubmit}
			className="vstack card px-4 py-2 overflow-y-auto align-items-center justify-content-evenly"
		>
			<div className="w-100 vstack h-100 gap-3">
				{params.mode == "add" && (
					<FormControl
						type="text"
						value={courseId}
						onKeyDown={(e) => {
							return /^[a-zA-Z0-9_]+$/.test(e.key) ? true : e.preventDefault();
						}}
						onChange={(e) => {
							setCourseId(e.target.value);
						}}
                        autoFocus

						placeholder="course id"
					/>
				)}
				{params.mode == "edit" && <h1>{courseId}</h1>}
				<FormControl
					type="text"
					value={courseName}
					onChange={(e) => setCourseName(e.target.value)}
					placeholder="course name"
				/>
				<hr className="w-100 m-0" />
				<div className="chapCont vstack gap-3">
					{chapters.map((chapter, chapterIndex) => (
						<>
							<DisplayChapManagement
								key={chapterIndex}
								chapter={chapter}
								deleteChapter={() =>
									setChapters(afterSplice(chapters, chapterIndex, 1))
								}
								setChapter={(e) =>
									setChapters(afterSplice(chapters, chapterIndex, 1, e))
								}
							/>
							{chapterIndex < chapters.length - 1 && (
								<hr className="m-0  w-75 align-self-center border border-2 rounded border-white" />
							)}
						</>
					))}
				</div>
				<Button
					onClick={() => {
						setChapters([
							...chapters,
							{
								chapter_id: null,
								chapter_name: "",
								topics: [{ topic_id: null, topic_name: "" }],
							},
						]);
					}}
					className="gap-2 d-flex align-items-center justify-content-center"
				>
					<FontAwesomeIcon icon={faAdd} />
					Chapter
				</Button>
				<hr className="w-100 m-0" />
				{warn == "" || (
					<div className="alert align-self-center alert-info d-flex max-w-min text-nowrap overflow-ellipsis align-items-center gap-2 mb-2 p-2 py-1 ">
						<FontAwesomeIcon icon={faInfoCircle} />
						{warn}
					</div>
				)}
				<Button type="submit" disabled={!formValid}>
					<FontAwesomeIcon icon={params.mode=="add"?faAdd:params.mode=="edit"?faEdit:faWarning} className="ms-2 me-2" />
					<span className="text-capitalize">
						{params.mode}
						&nbsp;Course
					</span>
				</Button>
			</div>
		</Form>
	);
}
async function getFromParams(params) {
	if (params.mode == "edit") {
		if (params.course_id) return await getCourseStruct(params.course_id);
		else throw new Error("course id not found");
	} else return true;
}
//below code is to show results
/////////////////////////////////////
function ManageCourse() {
	const params = useParams();
	return (
		<Suspense fallback={<Loading show={"loading course "} />}>
			<Await resolve={getFromParams(params).catch(console.log)}>
				<DisplayCourseManagement />
			</Await>
		</Suspense>
	);
}

export default ManageCourse;
