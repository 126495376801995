import React, { useContext, useState } from 'react';
import { Container, Stack, Row, Col, Button } from 'react-bootstrap';
import { UserContext } from '../context/userContext';
import { Link, useNavigate } from 'react-router-dom';
import Internship_Plan from '../images/Internship_Plan.png';

function Dashboard(props) {
    let navigate = useNavigate();
    let user = useContext(UserContext);
    const [showInstaQandA, setShowInstaQandA] = useState(false); 

    const handleInstaQandAClick = () => {
        setShowInstaQandA(true); 
    };

    if (user == null) {
        return (<Link className="btn btn-primary" to="/login">Login to continue</Link>);
    } else {
        return (
            <>
               
                <Stack>
                    <Row>
                        <Col>
                            <Container className="w-100 mb-2">
                                <h2>Exercises</h2>
                                <p><center>Structured tasks for hands-on examples of learning concepts</center></p>
                                <Link to="/ex">
                                    <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                </Link>
                            </Container>
                        </Col>
                        <Col>
                            <Container className="w-100 mb-2">
                                <h2>FAQ's</h2>
                                <p><center>Commonly asked questions providing quick clarification on key concepts</center></p>
                                <Link to="/faq">
                                    <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                </Link>
                            </Container>
                        </Col>
                        <Col >
                            <Container className="w-100 mb-2">
                            
                                <h2>Notes</h2>
                                <p><center>Concise summaries highlighting key points.Click on the notes in top to get started</center></p>
                                <Link to="/">
                                    <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                    </Link>
                            </Container>
                        </Col>
                    </Row>
                </Stack>
            </>
        );
    }
}
export default Dashboard;
