import React from 'react';
import PowerBi from '../Tutor/Power_Bi.pdf'
function PowerBiTutorial() {
    return (
        <iframe
            src={PowerBi}
            style={{ width: '100%', height: '600px', border: 'none' }}
            title="PowerBi Presentation"
        ></iframe>
    );
}

export default PowerBiTutorial;
